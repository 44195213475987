import {axJson} from './__axInstance__'
import aggregateErrors from './__axInstance__/aggregate_errors'
import { filterCryteries } from '@/components/local/tables/cryteries.js'
import getCurrentDateInline from '@/assets/functions/get_current_date_inline'
import Vue from 'vue'



export default {
    
    namespaced: true,
    state: {
        // кол-во загружаемых записей
        rowsPaginator: 3,

        contractData: [],
        contractError: null,
        contractFilter: null,
        contractPayload: false,
        contractTotalAmount: 0,

        cacheMonitor: null
    },
 
    actions: {

        async get({ commit, state }, { 
            init, 
            type, 
            startType = 'mount',
            role = 'agent',
            limit = {},
            sort = {},
            params = {},
            btnClick = false,
            isDraftsClient = false,
        }){

            const COUNT_FOR_FULL = 3

            let findClient = Vue.prototype.$cookies.get('last_found_client_one') || {}


            //снилс с телефоном нужно фильтровать
            //апи всеравно не принимает даже отфильтрованное

            if(typeof limit.count_from === 'undefined'){
                limit.count_from = 0
            }

            if(~['mount', 'filter'].indexOf(startType)){
                commit('clear', { init })
            }


            let { status, operation } = params,
                 dates = { status, operation },
                 sortDates = {},
                 splitLine = [],
                 rangeType = {}

            rangeType = {

                operation: {
                    to:   'dateto',
                    from: 'datefrom'
                },

                status: {
                    to:   'stage_dateto',
                    from: 'stage_datefrom'
                }

            }


            for(let period in dates){

                if(!dates[period]) continue;

                splitLine = dates[period].split('-')
                                         .map(date => date.replace(/[.]/g, '-'))


                if(period === 'operation'){

                    sortDates[rangeType.operation.from] = splitLine[0]

                    sortDates[rangeType.operation.to] = splitLine[1] 
                                                            ? splitLine[1] 
                                                            : getCurrentDateInline()

                }else{

                    sortDates[rangeType.status.from] = splitLine[0]

                    sortDates[rangeType.status.to] = splitLine[1] 
                                                            ? splitLine[1] 
                                                            : getCurrentDateInline()

                }

            }


            if(startType === 'filter'){
                commit('writeFilters', { init, data: sortDates })
            }


                
            await getContracts.call(this, { 
                                    stage: params.stage, 
                                    limit, 
                                    sort,
                                    role,
                                    findClient,
                                    isDraftsClient
                                })

        


            function insertSorting(sort, type){

                for(let cretery in filterCryteries[type]){
                    for(let prop in sort){

                        if(sort[prop].toString() === cretery){

                            sort[prop] = filterCryteries[type][cretery]
                            
                        }
                    }
                }
                
            }


            async function getContracts({ stage, limit, sort, role, findClient, isDraftsClient }){
                
                // для показа черновиков клиента, если мы заходим исключительно со страницы клиента
                const getDraftsClient = () => {

                    let searchDrafts = {}

                    if(window.location.pathname.includes('client') && isDraftsClient){
                
                
                        const client = Vue.prototype.$cookies.get('last_found_client_one'),
                            serNumber = client.passport_seriesnumber.toString()
                
                
                        const [middle_name, first_name, last_name] = client.dul_fio.split(' ')
                
                        const dul_series = serNumber.substring(0, 4),
                            dul_number = serNumber.substring(4)
                        
                        const birth_date = client.birth_date.replace(/\//g, '.')
                
                
                        searchDrafts = {middle_name, first_name, last_name, dul_series, dul_number, birth_date}
                        
                        
                    }

                    return searchDrafts

                }
                

                //тип фильтрации отличается
                // limit.count_from = (type === 'short') ? 0 : limit.count_from || 0
                limit.count_to   = (type === 'short') ? 3 : limit.count_from + COUNT_FOR_FULL

                // выполняем сопоставление 
                insertSorting(sort, 'contract')

                // забираем критерии поиска для клиента
                let creteryes = {}

                if(role === 'client'){

                    const memoryFio = findClient.dul_fio.split(' ')

                    const first_name = memoryFio[1],
                           last_name = memoryFio[0],
                         middle_name = memoryFio[2]

                    creteryes = {
                        first_name,
                        last_name,
                        middle_name,
                        snils: findClient.snils,
                    }
                }
                
                if(window.location.pathname !== '/drafts' && !stage){
                    stage = '-1, 2, 3, 4, 5'
                }

                let params = { 
                    action: 'get_ipo_contracts',
                    order_by: 'create_date',
                    order_by_direction: 'True',
                    ...state.contractFilter, 
                    ...limit,
                    ...creteryes,
                    count_from: 0,
                    stage,
                    ...sort,
                    ...getDraftsClient()
                }


                let  response = await axJson.get('ipo/contract_api', { params })
                     response = response.data


                const existErrors = aggregateErrors.call(this, { commit }, { data: response })

                commit('payload', { init })

                if(!existErrors) return;
            

                const data = response
                let doc  = null,
                    pers = null,
                    agent = null,
                    packData = [],
                    total_amount = Object.values(data)[0]?.total_amount || 0
                
                if(!data){
                    // здесь сообщение что нет договоров
                }

                

                for(let item in data){
                    
                    doc = data[item].doc_data
                    pers = data[item].personal_data
                    agent = data[item].agent_data

                    let method = null

                    if(!doc) continue

                    switch(type){

                        case 'short':
                            packData.push({
                                id: doc.id,
                                dateOper: doc.create_date?.split(' ')[0],
                                agent: `${agent?.last_name} ${agent?.first_name} ${agent?.middle_name}`,
                                status: doc.stage
                            })
                            break

                            case 'extended':

                                method = 'unshift'
    
                                if(sort.order_by_direction === 'False'){
                                    method = 'push'
                                }
    
                                packData[method]({
                                    id: doc.id,
                                    fio: `${pers.first_name} ${pers.last_name} ${pers.middle_name}`,
                                    dateOper: doc.create_date?.split(' ')[0],
                                    dateStatus: doc.stage_date?.split(' ')[0],
                                    agent: `${agent?.last_name} ${agent?.first_name} ${agent?.middle_name}`,
                                    status: doc.stage
                                })
                                break
    
                            case 'product':
    
                                method = 'unshift'
    
                                if(sort.order_by_direction === 'False'){
                                    method = 'push'
                                }
    
                                packData[method]({
                                    id: doc.id,
                                    fio: `${pers.first_name} ${pers.last_name} ${pers.middle_name}`,
                                    dateOper: doc.doc_create_date,
                                    product: {
                                        title: doc.product_name || doc.product_type_id + ' id продукта',
                                        // title: doc.product_name,
                                        desc:  'Описание продукта',
                                    },
                                    agent: `${agent?.last_name} ${agent?.first_name} ${agent?.middle_name}`,
                                    status: doc.stage
                                })
                                break
    
                            //agent_org_type был во всех трех

                    }

    
                }

                
                commit('fillData', { init, data: packData, btnClick, total_amount })

            }

        },


    },
    mutations: {


        clear(state, { init }){

            state[`${init}Data`]    = []
            state[`${init}Payload`] = false
            state[`${init}Filter`]  = null

        },

        writeFilters(state, { init, data }){

            state[`${init}Filter`] = data

        },

        fillData(state, { init, data, btnClick, total_amount }){

            state[`${init}Data`] = data

            state[`${init}TotalAmount`] = total_amount

        },

        payload(state, { init }){

            state[`${init}Payload`] = true
        },


        viewError(state, { init, errMessage }){

            state[`${init}Error`] = errMessage

        },

        
    },


}
