import {axJson} from './__axInstance__'
import aggregateErrors from './__axInstance__/aggregate_errors'
import currentDocId from './__axInstance__/current_doc_id'
import qs from "qs";
import Vue from "vue";


export default {

    namespaced: true,

    state: {
        path: false,
        data_errors: null,
        error: null
    },

    actions: {
        async confirmSms({ commit }, {verify, init}){

            commit('clear')

            //25 и 56 страница в тз
            const { code } = verify,
                 setCookie = Vue.prototype.$cookies

            let errMessage = '',
                data = null


            try{



                let { prevalidation } = verify
                prevalidation = (prevalidation) ? true : false
                prevalidation = `&prevalidation=${prevalidation}`

                data = await axJson.post(`/ipo/contract_api?action=confirm_ipo_information_with_sms${currentDocId(init) + prevalidation}`, { code })
                data = data.data
                

          
                switch(data?.descr){

                    case 'invalid_code':
                        errMessage = 'Введен неверный код'
                        break

                    case 'confirmation_code_input_limit_reached':
                        errMessage = 'Превышен лимит на количество ошибочных попыток ввода. Проект договора стерт'

                }

                const existErrors = aggregateErrors.call(this, { commit }, { data, localError: errMessage })

                if(!existErrors) return;

                if(existErrors){
                    Vue.prototype.$cookies.set('project_verify', JSON.stringify({
                        id: Vue.prototype.$cookies.get(`id_current_${init}`),
                        code
                    }))

                    commit('nextStep')
                }

            }catch(errMessage){
                commit('viewError', { errMessage })
            }

        }
    },
    mutations: {

        nextStep(state){
            state.path = true
        },

        viewDataError(state, { field_errors }){
            state.data_errors = field_errors
        },

        viewError(state, { errMessage }){
            state.error = errMessage
        },

        clear(state){
            state.path = false
            state.data_errors = null
            state.error = null
        }

    },
    getters: {

        currState(state){
            return state
        }

    }
}
