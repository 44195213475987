<template>
  
    <div class="v-history grid-dashboard">
        <div class="v-history-top grid-item">

            <Tables
                :name="name"
                :group="group"
                :typeForm="typeForm"
                :mainData="data"
                :tableStyle="tableStyle"
                :downloadMore="downloadMore"
                :inputsView="['operation']"
                :payload="payload"
                :TotalAmount="TotalAmount"
                :rowsPaginator="rowsPaginator"
            />


        </div>
    </div>
  
</template>

<script>
import { mapState } from 'vuex'
import Tables from '@/components/local/tables/index'

export default {
    name: 'v-history',

    data(){

        const { pathID } = this.redirectFromStatement()

        let path = this.$route.path,
            mode = ''

        if(path.includes('contract')){
            mode = 'contract'
        }

        return {
            contracts: [],
            mode,
            pathID,
            currPath: null,
            data: null,
            name: '',
            group: '',
            tableStyle: '',
            payload: false,
            typeForm: '',
            TotalAmount: 0
        }
    },

    watch: {

        "contractPayload"(){

            this.payload = this[this.group.slice(0, -1) + 'Payload']

        },

        "contractData": function(){
            this.data = this.contractData
            this.group = 'contracts'
            this.name = 'История коммуникаций по договорам'
            this.tableStyle = 'full_client_contract'
            this.typeForm = 'product'
            this.TotalAmount = this.contractTotalAmount
        },

    },

    computed: {


        ...mapState({


            contractTotalAmount:    state => state.getStatementContracts.contractTotalAmount,
            contractData:       state => state.getStatementContracts.contractData,
            contractError:      state => state.getStatementContracts.contractError,
            contractPayload:    state => state.getStatementContracts.contractPayload,

            rowsPaginator:       state => state.getStatementContracts.rowsPaginator,
        }),


    },

    async mounted(){

        const fioClient = this.$cookies.get('fio').toLowerCase()


        this.$store.dispatch('viewLeftButtonHeader/viewButton', {

            name: this.$lodash.startCase(fioClient),
            visible: true,
            workPath: 'HistoryClient',

            postFunc: () => {
                this.$router.push(`/client/${this.pathID}`)
            } 
        })

        await this.downloadMore({})

    },

    methods: {

        async downloadMore({ limit, startType, sort, btnClick, stages }){


            await this.$store.dispatch('getStatementContracts/get', {
                            init: this.mode,
                            type: this.group === 'contracts' ? 'product' : 'extended',
                            role: 'client',
                            startType,
                            btnClick,
                            limit,
                            sort,
                            params: {
                                client_id: this.pathID,
                                ...stages
                            },

                        })

        },


        redirectFromStatement(){


            let client = this.$cookies.get('last_found_client_one'),
                pathID = +this.$route.params.id

            if(client?.code !== pathID){
                this.$router.push({ name: 'home' })
            }

            return {
                client,
                pathID
            }

        },

    },

    components: {
        Tables
    }
}
</script>

<style>

</style>