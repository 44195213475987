import { axJson } from './__axInstance__'
import aggregateErrors from './__axInstance__/aggregate_errors'
import currentDocId from './__axInstance__/current_doc_id'
import qs from 'qs'
import Vue from "vue";

export default {

    namespaced: true,

    state: {
        path: false,
        error: null,
        isProcess: false,
        activeBtn: false,
        contractTick: 0
    },

    actions: {
        async sendSms({ commit }, {action, init}){


            commit('viewError', { errMessage: false })
            //25 и 55 страница в тз
            const SECONDS    = 10,
                  setCookie  = Vue.prototype.$cookies,
                  currentSms = +setCookie.get(`current_send_sms_${init}`),
                  allSms     = +setCookie.get(`all_send_sms_${init}`),
                  secToDeath = Math.ceil(((currentSms + SECONDS * 1000) - Date.now()) / 1000),
                  maxSms     = (init === 'contract') ? 5 : 10


            let errMessage = '',
                runTimer = null

            //если пользователь сделал 5-10 попыток, дальше функция не выполняется,
            //в UI выводится сообщение об ошибке

            console.log(allSms)

            if(allSms === maxSms){

                commit('viewError', {
                                    errMessage: 'Сервис не доступен'
                                })
                console.log('Сервис не доступен')

                return false


            }else if(action === 'init' && !allSms){

                runTimer = await sendToServer()

                //для контрактов, отсчет идет сразу (было ранее)
                // if(init === 'contract'){
                    changeBtnAndTimer(this.state, undefined, allSms)
                // }

            }else if(
                action === 'click' && allSms === 1
                ||
                action === 'click' && !currentSms
            ){

                runTimer = await sendToServer()

                changeBtnAndTimer(this.state, undefined, allSms)


            }else if(secToDeath > 0 && action === 'init'){

                changeBtnAndTimer(this.state, secToDeath, allSms)
            }


            function installCookie(){

                //запись кол-во отправленных смс

                Vue.prototype.$cookies.set(
                    `all_send_sms_${init}`,
                    +Vue.prototype.$cookies.get(`all_send_sms_${init}`) + 1
                )

                //установка текущей отправки на время
                Vue.prototype.$cookies.set(
                    `current_send_sms_${init}`,
                    Date.now(),
                    `${SECONDS}s`
                )
            }


            function changeBtnAndTimer(state, secToDeath, allSms){

                let index = -1,
                    timer = ''

                const intervalTick = () => {

                    state.isProcess = true

                    commit('step', { index, init })

                    commit('changeBtn', {
                        param: true,
                        index: index
                    })

                    if(index <= 0){

                        clearInterval(timer)
                        state.isProcess = false

                        commit('changeBtn', {
                            param: false,
                            index: index
                        })
                    }

                    if(index > 0) index--;

                    return true
                }
                // secToDeath > 0 && allSms !== 1
                if(secToDeath > 0){

                    index = secToDeath

                }else if(!secToDeath){

                    index = SECONDS
                }


                commit('changeBtn', {
                    param: false,
                    index: index
                })

                if(!runTimer && !secToDeath) return;

                intervalTick()

                timer = setInterval(() => {
                    intervalTick()
                }, 1000)

            }

            async function sendToServer(){

                try{

                    let data = null
                    
                    if(init === 'statement'){
                        // const id = setCookie.get('id_current_statement'),
                        data = await axTable.post(`/afo/pdbr/save`, qs.stringify({
                                step: 'send_sms',
                                declaration_number: Vue.prototype.$cookies.get('declaration_number'),
                            }))
                    }else{
                        data = await axJson.post(`ipo/contract_api?action=send_ipo_sms${currentDocId(init)}`, '')
                        data = data.data
                    }

                    //contracts
                    switch(data?.descr){

                        case 'phone_is_empty':
                            errMessage = 'Ошибка: мобильный телефон для отправки СМС отсутствует'
                            break

                        case 'information_already_confirmed':
                            errMessage = 'Ошибка: данные уже подтверждены СМС'
                            break

                        case 'sending_error':
                            errMessage = 'Ошибка отправки СМС'
                            break

                        case 'sending_limit_reached':
                            errMessage = 'Ошибка, превышен лимит на количество отправок СМС кода, запрос новых заблокирован'

                    }

                    //statements
                    switch(data?.descr){

                        case 'sms_already_send':
                            errMessage = 'Смс уже отправлено, повторная отправка возможна через минуту'

                    }


                    const existErrors = aggregateErrors.call(this, { commit }, { data, localError: errMessage, })


                    if(!existErrors) return;

                    installCookie()

                    return true
                    //сюда переместить код

                }catch(errMessage){
                    commit('viewError', { errMessage })
                }

            }

        },

        clearProjectSms({ }, { init }){

            const resetCookies = [`current_send_sms_${init}`, `all_send_sms_${init}`],
                       cookies = Vue.prototype.$cookies

            cookies.keys().forEach(item => {
    
                if (resetCookies.includes(item)){
                   cookies.remove(item)
                }
            })

        }

    },
    mutations: {

        step(state, { index, init }){
            if(index > -1) state[`${init}Tick`] = index
        },

        changeBtn(state, { param, index }){
            if(index > -1) state.activeBtn = param
        },

        viewError(state, { errMessage }){
            state.error = errMessage
        },

    },
    getters: {

        currState(state){
            return state
        }

    }
}
