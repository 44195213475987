import {axJson} from './../__axInstance__'

export default {
    
    namespaced: true,
    
    state: {
        deleted: null
    },

    actions: {

        async del({ commit }, { id }){

            commit('clear')


            try{
                const { data } = await axJson.post(`ipo/contract_api?action=delete_ipo_contract&id=${ id }`)

                if(data.result === 'ok'){
                    commit('deleted')
                }

                
            }catch(error){}

        },


    },
    mutations: {

        clear(state){
            state.deleted = null
        },

        deleted(state){
            state.deleted = true
        },
        
    },

}
