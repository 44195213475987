import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueFormulate from '@braid/vue-formulate'
import VueTippy, { TippyComponent } from "vue-tippy";
import VueMask from 'v-mask'
import VModal from 'vue-js-modal'
import VCalendar from 'v-calendar';
import Lodash from 'lodash'

//components-slots
import slotLabel from './components/slots/s-label.vue'

// adaptive module
import './utils/breakpoint_manager';

// styles
import '../node_modules/suggestions-jquery/less/suggestions.less'

// темы для всплывашек
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import './styles/style.scss'

Vue.config.productionTip = false

//функции помощники
import toStringFromNull from '@/assets/functions/to_string_from_null'
import isEmptyObj from '@/assets/functions/check_object_is_empty'

Vue.component("tippy", TippyComponent)
Vue.component('slotLabel', slotLabel)


Vue.use(VCalendar)
Vue.use(VueTippy);
Vue.use(VueCookies)
Vue.use(VueMask)
Vue.use(VModal)



Vue.prototype.$tippySetting = {
    // tooltipe
}


Vue.use(VueFormulate, {


  library: {
    password: {
      slotComponents: {
        prefix: 'slotLabel'
      },

      slotProps: {
        help: ['watchView']
      }

    },
    text: {
      slotComponents: {
        prefix: 'slotLabel'
      },

      slotProps: {
        help: ['icon', 'dateIcon']
      }
    }
  },


  classes: {

    //outerIsValid: 'valid',

    outerIsValid: (baseClasses, ctx) => {

      if(!baseClasses.value){

        return ctx.join(' ')

      }else if(!baseClasses.hasErrors && baseClasses.value){
        return ctx.join(' ') + ' valid'
      }
    },

    outerHasErrors: 'invalid',
    outerHasValue: 'has-value',

    outer: (classes, ctx) => {


      const focus = document.activeElement?.parentNode?.parentNode?.parentNode,
        focusName = focus?.querySelector('input')?.name

      if(classes.name === focusName && focus?.hasAttribute('data-type')){
        return 'wrapper-input focus'
      }

      return 'wrapper-input'
    },

  },


  //руссифицирование некоторых ошибок
  locales: {
    en: {
      required: ({ name }) => {

        switch(name){

          case 'firstPay':
            return 'Введите первоначальный взнос'

          default:
            return 'Поле обязательно к заполнению!'
        }

      },

      between: ({ args, name, value }) => {

        if(+value === args[0] || +value === args[1]){
          return;
        }

        switch (name) {

          case 'firstPay':
            return `Ошибка: размер взноса должен быть не менее ${args[0]} руб. и не более ${args[1]} руб.`

          case 'vznosPercent':
            return `Ошибка: размер взноса должен быть не менее 2% при оформлении в возрасте до 33 лет,
              не менее 4% при оформлении в возрасте после 33 лет, и не более 50%`
        }
      },

      number: () => 'Поле может содержать только цифры!',
      email: () => 'E-mail должен иметь вид: title@email.com',
      matches: () => 'В поле содержатся запрещенные символы!',
      min: ({ args }) => `Минимальное количество символов ${args[0]}`,
      date: ({ args }) => `Несуществующая дата, и/или неверный формат. Требуемый формат: "${args[0].replace('MM', 'мм')
                                                                                                   .replace('DD', 'дд')
                                                                                                   .replace('YYYY', 'гггг')}"`
    },
  },


  errorHandler(data){

    //schemes, errors - объект
    //fields          - массив объектов
      //field.name    - строка
      //field.value   - строка
      //field.cretery - массив строк

    const inputs = {}
    let countErrors = 0;

    if(!data.inputErrors) data.inputErrors = [];

    //получаем общее кол-во ошибок, для формы и инпутов
    (function getCountErrors(){

      const inputErrors = data.errors,
            formErrors = data.formErrors

      for(let field in inputErrors){

        const fieldErrors = inputErrors[field]?.errors?.length

        if(inputErrors[field].hasErrors){
          countErrors += fieldErrors
        }

      }

      countErrors += formErrors.length

    })();


    return {
      inputErrors: {...inputs, ...data.aggregate},
      formErrors: data.api && data.formErrors,
      countErrors: countErrors
    }

  }
});

Vue.prototype.$toStringFromNull = function(data){
  return toStringFromNull(data)
}

Vue.prototype.$isEmptyObj = function(obj){
  isEmptyObj(obj)
}

Vue.prototype.$modFormulate = {
  _focus(e){
    this._modClass(e, 'add')
 },
  // заглушка чтобы не крашилось
 }

Vue.prototype.$lodash = {
  cloneDeep: Lodash.cloneDeep,
  startCase: Lodash.startCase
}


Vue.prototype.$checkErrorField = function(errors, check){

    //check - массив проходимых вариантов разрешающих факторов
    //errors - массив с именами полей, в которых произошла ошибка

    let found = [],
        states = {},
        result = {},
        index = 1

    //собираем все ошибки в одну кучу
    for(let field in errors) {

      found.push({
        name: errors[field].name,
        state: errors[field].hasErrors
      })

    }

    //сортируем ошибки в группы-факторы
    for(let input of check){

      states[`factor_${index}`] = []

      for(let elem of found){

        if(~input.indexOf(elem.name)){
          states[`factor_${index}`].push(elem.state)
        }

      }
      index++

    }


    //фиксируем факт присутствия ошибки в группах
    index = 1

    for(let state in states){

      result[`factor_${index}`] = false

      if(~states[state].indexOf(true)){
        result[`factor_${index}`] = true
      }

      index++
    }
    return result


}

//кастомные маски
//не использовать в группе автозаполнения dadata!
Vue.prototype.$masks = {

  upperCyrilic(value){
    const clear = value.toUpperCase()
                       .replace(/[^А-Я- ]/g, '');
    return [clear]
  },

  upperSymbCyrilic(value){

    const clear = value.toUpperCase()
                       .replace(/[^А-Я0-9-.,:"'№ ]/g, '');

    return [clear]
  },

  emailLatin(value){

    const clear = value.replace(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/g, '');

    return [clear]
  }

}

//кастомные маски
//можно использовать в группе dadata
Vue.directive('maskPassCode', {

  bind(el, binding, vnode) {

    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }

      let needProps = vnode.data.model.expression.split('.')
      let x = (needProps[1])
                  ? vnode.context.$data[needProps[0]][needProps[1]]
                  : vnode.context.$data[needProps[0]]
      x = x.replace(/\D/g, '')
           .match(/(\d{0,3})(\d{0,3})/);

      x = (!x[2] ? x[1] : x[1] + ' ' + x[2]);

      (needProps[1])
          ? vnode.context.$data[needProps[0]][needProps[1]] = x
          : vnode.context.$data[needProps[0]] = x

    }
  },

});

Vue.directive('maskSymbCyrilic', {

  bind(el, binding, vnode) {

    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }


      let needProps = vnode.data.model.expression.split('.')

      let x = (needProps[1])
                  ? vnode.context.$data[needProps[0]][needProps[1]]
                  : vnode.context.$data[needProps[0]]

      x = x.toUpperCase()
           .replace(/[^А-Я0-9-.,:"'№ ]/g, '');

      (needProps[1])
           ? vnode.context.$data[needProps[0]][needProps[1]] = x
           : vnode.context.$data[needProps[0]] = x


    }
  },

});

Vue.directive('maskSymbNumberBik', {

  bind(el, binding, vnode) {

    el.oninput = function(e) {

      if (!e.isTrusted) {
        return;
      }

      let needProps = vnode.data.model.expression.split('.')
      let x = vnode.context.$data[needProps[0]][needProps[1]]
      if (x.length > 9) {
        vnode.context.$data[needProps[0]][needProps[1]] = x.slice(0, -1)
      }

    }
  },

});

Vue.directive('maskSymbNumberCorrScore', {

  bind(el, binding, vnode) {
    el.oninput = function(e) {

      if (!e.isTrusted) {
        return;
      }

      let needProps = vnode.data.model.expression.split('.')
      let x = vnode.context.$data[needProps[0]][needProps[1]]
      if (x.length > 20) {
        vnode.context.$data[needProps[0]][needProps[1]] = x.slice(0, -1)
      }

    }
  },

});


Vue.directive('maskDateRange', {

  bind(el, binding, vnode) {
    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }

      let needProps = vnode.data.model.expression.split('.')
      let x = vnode.context.$data[needProps[0]][needProps[1]]
      let str = ''
      vnode.context.$data[needProps[0]][needProps[1]] = `${x[0]}${x[1]}.${x[2]}${x[3]}.${x[4]}${x[5]}${x[6]}${x[7]}`
      //vnode.context.$data[needProps[0]][needProps[1]] =
      //if (x.length > 20) {
        //vnode.context.$data[needProps[0]][needProps[1]] = x.slice(0, -1)
      //}

    }
  },

}); // прототип маски для даты

//изменение состояния инпута - фокус/блюр  (общий)
Vue.directive('changeFocusBlur', {

  bind(el) {

    const input = el.querySelector('input[type=text], input[type=number], input[type=password], input[type=email]')

    input.addEventListener('focus', () => {
      el.classList.add('focus')
    });

    input.addEventListener('blur', () => {
      el.classList.remove('focus')
    });

  },

});


Vue.directive('doubleDisable', {


  bind(el) {

    setTimeout(() => {

      const checkInput = el.querySelector('input, select').hasAttribute('disabled')


      if(checkInput){

        el.classList.add('disabled')

      }else{
        el.classList.remove('disabled')
      }

    }, 0)


  },

  update(el){


    setTimeout(() => {

      const checkInput = el.querySelector('input, select').hasAttribute('disabled')

      if(checkInput){

        el.classList.add('disabled')

      }else{
        el.classList.remove('disabled')
      }

    }, 0)


  }

});

//временно снял с инпутов - Владислав
Vue.directive('createDateDatepicker', {

  bind(el, binding, vnode) {
    el.oninput = function() {

      const input = el.querySelector('input[type=text]')
      vnode.context.$data.dateForDatePicker[input.name] = new Date(input.value.split('.').reverse().join('.'))
    }
  }

})



//заполнение реактивных переменных при перезагрузке страницы,
//если в кукках сохранился id для запроса к получению текущего договора
Vue.prototype.$fillActual = function(data, getContract, blockData = 'personal_data', otherGroup){

  const respData = getContract?.[blockData] || getContract

  if(!respData) return data;

  let result = {},
    keysResp = Object.keys(respData)

  for(let item in data){
    if(~keysResp.indexOf(item)){

      result[item] = respData[item]

    }else{

      switch(item){

        case "dul_series_number":
        case "dul_ser_passport":
          result[item] = respData['dul_series'] + ' ' + respData['dul_number']
          break;


      }

    }

  }

  // далее обрабатывается otherGroup
  // сделано для предзаполнения из других групп
  for(let item in otherGroup){

    result[item] = getContract[otherGroup[item]][item]

  }

  return result

}

//функция для блокировки инпута, исходя из переданного чекбокса
//также изменяет содержимое переданной схемы, для разрешения пропуска данных у Formulate
Vue.prototype.$changeCheckInput = function({reqDis, localScheme, nameGlobalScheme, propLocal }){

  //двойной ререндер
  //req
  //[0] свойство required у input
  //[1] свойство disabled у input
  //[2] свойство disabled у checkbox'a
  //[3] свойство v-model у checkbox'a

  // input
  // свойство v-model у input

  const data = Object.entries(reqDis)

  Object.assign(reqDis, {
    [data[0][0]]: data[3][1] ? false : true,
    [data[1][0]]: data[3][1] ? true : false,
  })

  Vue.prototype.$deactivateScheme.one({ reqDis, localScheme, nameGlobalScheme, propLocal })

}


Vue.prototype.$deactivateScheme = {

  //деактивирует одну переданную локальную схему
  //необязательным является передавать сюда переменную отключения html элемента
  one({
    activate = null,
    reqDis = null,
    localScheme,
    nameGlobalScheme,
    propLocal
  }){

    const schemes = Vue.prototype.$lodash.cloneDeep(
                                    Vue.prototype.$schemes
                                  )
    if(propLocal){

      if(activate || !reqDis.reqName){


        schemes[propLocal][1][0] = 'optional'
  
        localScheme[propLocal] = schemes[propLocal]
  
      }else{
  
        schemes[propLocal][1][0] = 'required'
  
        localScheme[propLocal] = schemes[propLocal]
  
      }

    }else{

      if(activate || !reqDis.reqName){

        schemes[nameGlobalScheme][1][0] = 'optional'
  
        localScheme = schemes[nameGlobalScheme]
  
      }else{
  
        schemes[nameGlobalScheme][1][0] = 'required'
  
        localScheme.push(...schemes[nameGlobalScheme])
  
      }

    }

  },

  //деактивирует все локальные схемы для порционально
  //переданных в группе данных. Работает в обе стороны.
  async more({ activateForm, getHasErrors = null, choice = null }){

    const filterRequired = ({ nameScheme, activateForm, localShemes}) => {
      

      const scheme = localShemes[nameScheme]?.slice(0) || []
      let index = 0

      for(let item of scheme){

        if(~this.dataSchemes[activateForm]?.indexOf(nameScheme)){

          index = item.indexOf('optional')

          if(index > -1){
              item[index] = 'required'
              break
          }

        }else{

          index = item.indexOf('required')

          if(index > -1){
              item[index] = 'optional'
              break
          }

        }

      }

      return scheme
    }

    //модифицируем основные схемы
    for(let item in this.localShemes){

      Object.assign(this.localShemes, { [item]: filterRequired({
                                                          activateForm,
                                                          nameScheme: item,
                                                          localShemes: this.localShemes
                                                        })})
    }

    //модифицируем кастомные схемы
    if(choice){

      choice.map(custom => {

        for(let item in this.customSchemes){

          if(custom === item){

            Object.assign(this.customSchemes, { [custom]: filterRequired({
                                                  activateForm,
                                                  nameScheme: custom,
                                                  localShemes: this.customSchemes
                                              })})
          }
        }
      })
    }

    //получаем индикатор ошибок
    if(getHasErrors){

      let hasErrors = ''

      hasErrors = await new Promise(resolve => {

        setTimeout(() => {

          this.$children.find(form => {

            if(form.name === getHasErrors){
              hasErrors = form.hasErrors
              return true
            }

          })

          resolve(hasErrors)

        }, 50)

      })

      return hasErrors
    }

  }


}

Vue.prototype.$schemes = {

  //required обязан быть под индексом 1[0]!!!
  login: [
    ['bail'],
    ['required', 'trim'],
    ['min', 3, 'length'],
    ['matches', /^[a-zA-Z-_]+$/],
  ],
  password: [
    ['bail'],
    ['required', 'trim'],
    ['min', 3, 'length'],
    ['matches', /^[^а-яА-Я ]+$/]
  ],
  oneNameFio: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 2, 'length'],
    ['matches', /^[а-яА-Я- ]+$/],
  ],

  middleNameScheme: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 2, 'length'],
    ['matches', /^[а-яА-Я- ]+$/],
  ],

  fio: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 2, 'length'],
    ['matches', /^[а-яА-Я-]{2,100} +[а-яА-Я-]{2,100} +[а-яА-Я-]{2,100}$/],
  ],
  gender: [
    ['bail'],
    ['optional']
  ],
  address: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 10, 'length'],
    ['matches', /^[^a-zA-Z]+$/],
  ],

  locationAddressScheme: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 10, 'length'],
    ['matches', /^[^a-zA-Z]+$/],
  ],

  phone: [
    ['bail'],
    ['optional'],
    ['min', 15, 'length'],
    ['matches', /^\+[0-9 ]+$/],
  ],
  snils: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 14, 'length'],
    ['matches', /^[0-9- ]+$/]
  ],
  bankName: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 5, 'length']
  ],
  corrScore: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 20, 'length']
  ],
  bikBank: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 9, 'length']
  ],
  clientRs: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 20, 'length']
  ],
  clientCard: [
    ['bail'],
    ['optional'],
    ['min', 19, 'length']
  ],
  passTitle: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 10, 'length'],
    ['matches', /^[а-яА-Я0-9-.,:№' ]+$/],
  ],
  passCode: [
    ['bail'],
    ['optional'],
    ['min', 7, 'length'],
    // ['matches', /^[0-9 ]+$/],
  ],
  passNumSeries: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 11, 'length'],
    ['matches', /^[0-9 ]+$/],
  ],
  email: [
    ['bail'],
    ['optional', 'trim'],
    ['matches', /^[a-zA-Z0-9-._]+@[a-z]+\.[a-zA-Z]+$/],
    ['min', 5, 'length']
  ],
  inn: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 12, 'length'],
  ],
  placeBirth: [
    ['bail'],
    ['optional', 'trim'],
    ['min', 3, 'length'],
    ['matches', /^[а-яА-Я0-9-.,: ]+$/],
  ],
  passNumber: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 6, 'length'],
  ],
  smsCode: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 4, 'length'],
  ],
  serPassCode: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 4, 'length'],
  ],

  date: [
    ['bail'],
    ['optional', 'trim'],
    ['date', 'DD.MM.YYYY']
  ],
  //две идентичные схемы. Проще так. Требуется
  //для активации/деактивации проверок
  dulDate: [
    ['bail'],
    ['optional', 'trim'],
    ['date', 'DD.MM.YYYY']
  ],

  birthDate: [
    ['bail'],
    ['optional', 'trim'],
    ['date', 'DD.MM.YYYY']
  ],

  datePay: [
    ['bail'],
    ['optional', 'trim'],
    ['date', 'DD.MM.YYYY']
  ],
  
  datePayScheme:[
    ['bail'],
    ['optional', 'trim'],
    ['date', 'DD.MM.YYYY']
  ],
  // ========================== //
  contract_num: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 4, 'length']
  ],
  firstPay: [
    ['bail'],
    ['optional'],
    ['number'],
    ['between', 1000, 600000]
  ],
  vznosPercent: [
    ['bail'],
    ['optional'],
    ['number'],
    ['between', 1, 11]
  ],
  numDoc: [
    ['bail'],
    ['optional'],
    ['number'],
    ['min', 6, 'length']
  ],
  workPlace: [
    ['bail'],
    ['optional'],
    ['between', 3, 30]
  ],
}


// статика
// Vue.prototype.$inputFocus = function(e) {
//     let inputCurrent = e.target;
//     let wrapperInput = inputCurrent.closest('.wrapper-input');

//         if(inputCurrent.value == '') {
//             wrapperInput.classList.add('input-focus');
//         } else {
//             wrapperInput.classList.remove('input-focus');
//         }
// }

let vm = new Vue({
  router,
  store,
  axios,
  render: h => h(App)
})


vm.$mount('#app')
