import {axJson} from "./__axInstance__";
import Vue from "vue";
import router from "../../router";

export default {

    namespaced: true,

    actions: {
        async deAuth() {
            try {

                const { data } = await axJson.post('/ipo/contract_api?action=deauth', {})

                if (data.status === 'success') {
                    Vue.prototype.$cookies.keys().forEach(cookie => Vue.prototype.$cookies.remove(cookie))
                    router.push({name: 'auth'})
                }

            } catch (e) {
                console.log(e)
                alert('Ошибка доступа к бд') // было бы неплохо на проде это вырезать
            }
        }
    },
    mutations: {},
}
