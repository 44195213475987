<template>
  <div class="v-history grid-dashboard">
    
    <div class="v-history-table grid-item">
        <!-- v-if="tableStyle" -->
      <Tables
        :name="name"
        :group="group"
        typeForm="extended"
        :mainData="data"
        :tableStyle="tableStyle"
        :downloadMore="downloadMore"
        :inputsView="inputsView"
        :payload="payload"
        :TotalAmount="TotalAmount"
        :rowsPaginator="rowsPaginator"
      />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tables from '@/components/local/tables/index'

export default {

  name: 'v-history',

  data(){

    return {
      contracts: [],
      currPath: null,
      data: null,
      name: '',
      group: '',
      tableStyle: '',
      payload: false,
      TotalAmount: 0,
      inputsView: []
    }

  },

  watch: {

    "contractPayload"(){

      this.payload = this[this.group.slice(0, -1) + 'Payload']

    },

    "contractData": function(){
      this.data = this.contractData
      this.group = 'contracts'
      this.name = 'История коммуникаций по договорам'
      this.tableStyle = 'full_agent_contract'
      this.TotalAmount = this.contractTotalAmount
      this.inputsView = ['operation', 'status']
    },


  },

  computed: {
      
    ...mapState({

        contractData:       state => state.getStatementContracts.contractData,
        contractError:      state => state.getStatementContracts.contractError,
        contractPayload:    state => state.getStatementContracts.contractPayload,
        contractTotalAmount:   state => state.getStatementContracts.contractTotalAmount,

        rowsPaginator:       state => state.getStatementContracts.rowsPaginator,
    }),

  },

  async mounted(){

      this.$store.dispatch('viewLeftButtonHeader/viewButton', {

        name: 'К поиску клиента',
        visible: true,
        workPath: 'History',

        postFunc: () => {
            this.$router.push({ name: 'home' })
        } 
    })

    await this.getDataWithPath()
  },


  methods: {

    async downloadMore({ limit, startType, sort, btnClick, stages}){

        
      await this.$store.dispatch('getStatementContracts/get', {
                      init: this.currPath,
                      type: 'extended',
                      startType,
                      btnClick,
                      limit,
                      sort,
                      params: { ...stages }
                  })

    },

    async getDataWithPath(){

      const path = this.$route.path

      if(path.includes('contract')){

        this.currPath = 'contract'

      }else{

        //404

      }

      await this.downloadMore({})

    }


  },

  components: {
    Tables
  }

}
</script>

<style>

</style>