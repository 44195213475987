<template>
  <div class="v-not-found">
      <div class="v-not-found-block">
          <div class="v-not-found-img">
              <img src="@/assets/img/svg/404.svg" alt="">
          </div>
          <div class="v-not-found-title">
              <p>cтраница не найдена</p>
          </div>
          <div class="v-not-found-desc">
              <p>К сожалению, мы не смогли найти эту страницу, но вы можете</p>
              <router-link
                    :to="{name: 'home'}"
                    tag="button"
                    class="btn-big btn-text link-inline"
                    >
                    Вернуться на главную
                </router-link>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'v-not-found'
}
</script>
