<template>


    <v-date-picker v-model='datesForCalendar' 
                   :is-range="type === 'period'"
    >

        <template #default="{ togglePopover }">

            <!-- примеры вызовов -->
            <!-- одиночная дата -->
            
            <!-- <Celendar type="one" 
                      :dates="dates"
                      prop="operation"
                      name="operation"
                      label="Выберите дату"
                      :disabled="false"
                      classes="big filter-date"
            /> -->

            <!-- для даты в периоде, не указываем полный список свойств -->

            <!-- <Celendar type="period" 
                      :dates="dates"
                      prop="status"
            /> -->

            <FormulateInput
                v-if="type === 'period'"
                name="filter-celendar"
                ref="celendar"
                type="text"
                :dateIcon="true"
                label= "Выберите период"
                class="big filter-date"
                autocomplete="off"
                @mousedown="trueFocus"
                @mouseup="trueFocus"
                @blur="changeDateForCalendar"
                @click="togglePopover"
                @keydown="fillDates"
                v-mask="'##.##.####-##.##.####'"
                v-model="dates[prop]"
                v-changeFocusBlur
                error-behavior="submit"
            />

            <FormulateInput
                v-if="type === 'one'"
                ref="celendar"
                type="text"
                :dateIcon="true"

                :name="name"
                :label="label"
                :class="classes"
                :disabled="disabled"
                :validation="validation"

                autocomplete="off"
                @mousedown="trueFocus"
                @mouseup="trueFocus"
                @blur="changeDateForCalendar"
                @click="togglePopover"
                @keydown="fillDates"
                v-mask="'##.##.####'"
                v-model="dates[prop]"
                v-changeFocusBlur
                v-doubleDisable
                :error-behavior="liveBehavior || 'submit'"
            />

        </template>
    </v-date-picker>



</template>

<script>


//крайний баг компонента - не выбирается одна дата для инпута в периоде

export default {

  name: 'v-filters',

  props: {
    type: String,   //period, one
    dates: Object,  //object with dates
    prop: String,   //prop in object with dates
    parentFunc: Function,
    inputFunc: Function,

    classes: String,
    validation: Array,
    name: String,
    disabled: Boolean,
    label: String,

    liveBehavior: String,
  },

  computed: {

    currWatch: function () {
      return this.dates[this.prop]
    }

  },

  watch: {

    'dates.status': function(){

      this.keyEvent = false

    },


    'datesForCalendar': function() {
    

      let { start, end } = this.datesForCalendar || {},
          startLocal = start?.toLocaleDateString(),
          endLocal = end?.toLocaleDateString()

      if(this.type === 'one'){
        
        start = this.datesForCalendar
        startLocal = start?.toLocaleDateString()

      }


      if(start && !end){

        this.dates[this.prop] = startLocal

      }else if(!start && !end){

        this.dates[this.prop] = ''

      }else if(startLocal !== endLocal){

        this.dates[this.prop] = startLocal + '-' + endLocal
        
      }else if(startLocal === endLocal && !this.keyEvent){

        this.dates[this.prop] = startLocal
        this.keyEvent = true

      }


    },

    "currWatch": function(){

      if(this.inputFunc){

        setTimeout(() => {
          this.inputFunc()
        })

      }


      if(this.parentFunc){

        setTimeout(() => {
          this.parentFunc(this.dates[this.prop])
        })

      }

    }

  },

  mounted(){



  },

  data(){

    return {

      keyEvent: true,

      datesForCalendar: {

        start: null,
        end: null,

      },


    }

  },

  methods: {


    trueFocus(){

      const input = this.$refs.celendar.$el.querySelector('input')

      if(input){

        setTimeout(() => {
          input.selectionStart = input.value.length
        }, 0)
        
      }

    },

    fillDates(){

      this.trueFocus()

      setTimeout(() => {

        const celendar = this.$refs.celendar.$parent

        celendar.showPopover()
        
        if(!celendar) return;

        const dates = this.dates[this.prop]
        
        let [start, end] = dates?.split('-') || []

        if(start?.length < 10 && end?.length < 10){
          return;
        }

        if(start?.length !== 10){
          return
        }

        start = start.split('.')
                          .reverse()
                          .join('-')

        if(this.type === 'one'){

          celendar.updateValue(start)

        }else{

          celendar.updateValue({ start: start,
                                end: start.split('.')
                                            .reverse()
                                            .join('-') 
                                      })
        }

        celendar.move(start)

        
        if(end?.length !== 10){
          this.keyEvent = true
          return;
        }

        celendar.updateValue({ start: start,
                              end: end.split('.')
                                        .reverse()
                                        .join('-') 
                                    })

      }, 100)

    },

    async changeDateForCalendar() {


      if (this.dates[this.prop] === null) {
        return
      }


      const date = this.dates[this.prop]

      const [ date1, date2 ] = date.split('-'),
                  splitDate1 = date1?.split('.') || [],
                  splitDate2 = date2?.split('.') || []

      let bits = {
        start: {
          day: splitDate1[0],
          month: splitDate1[1],
          year: splitDate1[2]
        }
      }

      if(date.includes('-') && date.length !== 11){



        bits = {

          end: {
            day: splitDate2[0],
            month: splitDate2[1],
            year: splitDate2[2]
          }
        }

        if((bits.end.day > 31 || bits.end.month > 12)
            &&
          date2.length === 10)
        {

          this.dates[this.prop] = date1

        }


        if(date1.length < 10 || date.length === 11){

          this.dates[this.prop] = ''


        }else if(date2.length < 10){

          this.dates[this.prop] = date1

        }

      }else if(date.length === 11 && bits.start.day <= 31 && bits.start.month <= 12){

        this.dates[this.prop] = date.replace('-', '')


      }else if(date.length === 10 && (bits.start.day > 31 || bits.start.month > 12)){

        this.dates[this.prop] = ''


      }else if((date1.length === 10 && (bits.start.day > 31 || bits.start.month > 12)) || date.length < 10){
        
        this.dates[this.prop] = ''

      }

    },

  }
}
</script>

