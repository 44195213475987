<template>
    <div class="v-body">
        <router-view />
    </div>
</template>
<script>

export default {
    name: 'v-body',

}
</script>