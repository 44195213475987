import isEmptyObj from '@/assets/functions/check_object_is_empty'

// ФУНКЦИЯ ИСПОЛЬЗУЕТСЯ ПРЕИМУЩЕСТВЕННО ДЛЯ ВЕТКИ ДОГОВОРОВ!!!
function clear(data, mode = null){

    let params = {...data},
        docData = {},
        forDoc = ['job_org_id', 'product_type_id', 'vznos_type', 'pens_pay_period', 'scheme', 'pens_pay_duration', 'payment_date', 'payment_method', 'vznos_summ', 'payment_sum']


    for(let field in params){

        if(!params[field]){
            delete params[field]
            continue
        }

        switch(field){

            case 'mobile_phone':
                params[field] = cleanSpace(params[field])
                break

            case 'dul_ser_passport':
                let split = params[field].split(' ')
                params['dul_series'] = split[0]
                params['dul_number'] = split[1]
                delete params[field]
                break

            case 'birth_date':
                params[field] = oldFormatDate(params[field], mode)
                break

            case 'passport_seriesnumber':
                params[field] = cleanSpace(params[field])
                break

        }


    }

    // выполняем перемещение в группу doc_data если есть соответствующий свойства
    for(let field in params){

        if(forDoc.includes(field)){

            docData[field] = params[field]
            delete params[field]

        }

    }



    return {
        personal_data: !isEmptyObj(params) ? params : {},
        doc_data:      !isEmptyObj(docData) ? docData : {}
    }

}


const cleanSpace = function(data){ 
    return data?.replace(/[ ]/g, '') || null
}

const oldFormatDate = function(data, mode){
    
    if(mode === 'old'){

        return data.split('.')
                    .reverse()
                    .join('-')
    }

    return data
}


export { clear }