import {axJson} from './../__axInstance__'
import aggregateErrors from './../__axInstance__/aggregate_errors'
import currentDocId from './../__axInstance__/current_doc_id'
import { clear } from './../__axInstance__/cleaners'
import Vue from "vue";

export default {
    
    namespaced: true,
    state: {
        path: false,
        data_errors: null,
        error: null
    },
 
    actions: {
        async add({ commit }, { params, init }){
            //28 и 50 страница в тз
            commit('clear')

            let errMessage = ''

            console.log(params)

            params = clear(params) 

            try{
                const {data} = await axJson.post(`ipo/contract_api?action=add_ipo_contract${currentDocId(init)}`, params)


                if(data.descr === 'denied_for_this_contract_stage'){

                    errMessage = `Ошибка: попытка изменить договор на этапе, когда это уже невозможно (после этапа
                        прохождения идентификации по СМЭВ/ЕСИА и персонификации договора, stage >= 2)`
                }


                const existErrors = aggregateErrors.call(this, { commit }, { data, localError: errMessage, })

                if(!existErrors) return;
                
                await this.dispatch('checkSmev/check', { root: true })

                
                if(data.id){

                    Vue.prototype.$cookies.set('id_current_contract', data.id);
                }
                
                commit('nextStep')

                
            }catch(error){
                console.dir(error)
                commit('viewError', { errMessage: error })
            }

        },


    },
    mutations: {

        nextStep(state){
            state.path = true
        },

        viewDataError(state, { field_errors }){
            state.data_errors = field_errors
        },

        viewError(state, { errMessage }){
            state.error = errMessage
        },


        clear(state){
            state.path = false
            state.data_errors = null
            state.error = null
        }
        
    },
    getters: {
        
        currState(state){
            return state
        }

    }
}
