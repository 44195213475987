import {axJson} from './../__axInstance__'
import currentDocId from './../__axInstance__/current_doc_id'

export default {
    
    namespaced: true,

    state: {
        data: {}
    },

    actions: {

        async get({ commit }, {}){

            const group = '' || 'test' //в будущем ожидаются настоящие группы

            const { data } = await axJson.get(`/ipo/contract_api?action=get_job_org_list`)

            delete data.nameHost

            localStorage.setItem('organizations', JSON.stringify(
                                                        Object.values(data)
                                                    ))
            // commit('setDate', data)

        }
    },
    // mutations: {

    //     setData(state, data){
    //         state.data = data
    //     },

    // },
    // getters: {
        
    //     currState(state){
    //         return state
    //     }

    // }
}
