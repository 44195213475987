<template>
    <div class="v-main-layout service">
        <v-sidebar />
        <v-body />
    </div>
</template>
<script>

import vSidebar from '../elements/v-sidebar'
import vBody from './v-body'

export default {
    name: 'v-main-layout',
    components: {
        vSidebar,
        vBody
    }
}
</script>