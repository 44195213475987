<template>

    <button class="btn-middle
                    primary
                    btn-text"
            :disabled="hasErrors || disabled"
            @click.prevent="search.call(null)">
        Найти
    </button>

</template>

<script>



export default {

  name: 'v-button_search',

  props: {
    hasErrors: Boolean,
    search: Function,
    
    disabled: {
      type: Boolean,
      default: false
    }

  },


}
</script>

