export default {
    namespaced: true,
    state: {
        currentSize: 'lg',
        currentRatio: 1,
        currentFontSize: 10
    },
    getters: {},
    mutations: {
        ['SET_CURRENT_SIZE'](state, size) {
            state.currentSize = size;
        },
        ['SET_CURRENT_RATIO'](state, ratio) {
            state.currentRatio = ratio;
        },
        ['SET_CURRENT_FONT_SIZE'](state, fontSize) {
            state.currentFontSize = fontSize;
        }
    }
};
