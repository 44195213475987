import Vue from 'vue'
import Vuex from 'vuex'

import home from './modules/home'
import findClient from './modules/statement_group/find_client'
import sendSms from './modules/send_sms'
import confirmSms from './modules/confirm_sms'
import sendLoging from './modules/send_loging'
import dadataHelper from './modules/dadata_helper'
import auth from './modules/contract_group/auth'
import deAuth from "./modules/deAuth";
import getProducts from './modules/contract_group/get_products'
import getExistContract from './modules/contract_group/get_exist_contract'
import getOrganization from './modules/contract_group/get_organization'
import finalizeContract from './modules/contract_group/finalize_contract'
import addBitContract from './modules/contract_group/add_bit_contract'
import deleteIpoContract from './modules/contract_group/delete_ipo_contract'
import getStatementContracts from './modules/get_statements_contracts'
import screen from './modules/screen'
import checkSmev from './modules/check_smev'
import uiKitData from './modules/uikit_data'
import viewLeftButtonHeader from './modules/components/left_button_header'
import modalForErrorIdent from './modules/components/modal_for_error_identification'


// import excel from './modules/excel'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    auth,
    home,
    addBitContract,
    deleteIpoContract,
    getExistContract,
    getStatementContracts,
    getProducts,
    findClient,
    sendSms,
    confirmSms,
    sendLoging,
    dadataHelper,
    screen,
    checkSmev,
    viewLeftButtonHeader,
    modalForErrorIdent,
    finalizeContract,
    uiKitData,
    getOrganization,
    deAuth,
    // excel
  }
})
