//в дальнейшем избавиться от этого файла
export default {
    namespace: true,
    state: {
        table1: {
            thead: {
                dateOper: 'Дата операции',
                agent: 'Агент',
                thing: 'Предмет обращения',
                status: 'Статус обращения'
            },
            tbody: [
                {
                    id: 1,
                    dateOper: '12.03.2021',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение банковских реквизитов',
                    status: 'На рассмотрении',
                    class: 'default'
                },
                {
                    id: 2,
                    dateOper: '12.03.2021',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Оформление договора',
                    status: 'Оплачено',
                    class: 'accepted'
                },
                {
                    id: 3,
                    dateOper: '12.03.2021',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение банковских реквизитов',
                    status: 'Отклонено',
                    class: 'rejected'
                },
            ]
        },
        table2: {
            thead: {
                empty1: '',
                typeProduct: 'Тип продукта',
                opened: 'Открыт',
                status: 'Статус',
                empty2: ''
            },
            tbody: [
                {
                    id: 1,
                    typeData: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план',
                        account: 'Счет № 123-123-456 78'
                    },
                    date: '01.02.2018',
                    status: 'Открыт'
                },
                {
                    id: 2,
                    typeData: {
                        title: '',
                        desc: 'Обязательное пенсионное страхование',
                        account: 'Счет № 123-123-456 78'
                    },
                    date: '01.02.2018',
                    status: 'Открыт'
                },
                {
                    id: 3,
                    typeData: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план',
                        account: 'Счет № 123-123-456 78'
                    },
                    date: '01.02.2018',
                    status: 'Открыт'
                },
                // {
                //     id: 4,
                //     typeData: {
                //         title: 'Дополнительные возможности',
                //         desc: 'Индивидуальный пенсионный план',
                //         account: 'Счет № 123-123-456 78'
                //     },
                //     date: '01.02.2018',
                //     status: 'Открыт'
                // },
                // {
                //     id: 5,
                //     typeData: {
                //         title: 'Дополнительные возможности',
                //         desc: 'Индивидуальный пенсионный план',
                //         account: 'Счет № 123-123-456 78'
                //     },
                //     date: '01.02.2018',
                //     status: 'Открыт'
                // },
            ]
        },
        table_history: {
            thead: {
                dateOper: 'Дата операции',
                fio: 'ФИО клиента',
                agent: 'Агент',
                thing: 'Предмет обращения',
                dateStatus: 'Дата статуса',
                status: 'Статус обращения'
            },
            tbody: [
                {
                    id: 1,
                    dateOper: '12.03.2019',
                    fio: 'Куплинов Аркадий Витальевич',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '12.03.2019',
                    status: 'На рассмотрении',
                    class: 'default'
                },
                {
                    id: 2,
                    dateOper: '08.03.2019',
                    fio: 'Косухина Светлана Николаевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Принято фондом',
                    class: 'accepted'
                },
                {
                    id: 3,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 4,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '07.03.2019',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 5,
                    dateOper: '14.02.2019',
                    fio: 'Абрамович Екатерина Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '14.02.2019',
                    status: 'Принято фондом',
                    class: 'accepted'
                },
                {
                    id: 6,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 7,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '07.03.2019',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 8,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '07.03.2019',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 9,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '07.03.2019',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 10,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 11,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 12,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    appeal: 'Изменение персональных данных',
                    dateStatus: '08.03.2019',
                    status: 'Отправлено на доработку',
                    class: 'default'
                }
            ]
        },
        table_history_client: {
            thead: {
                dateOper: 'Дата операции',
                fio: 'ФИО клиента',
                agent: 'Агент',
                product: 'Продукт',
                thing: 'Предмет обращения',
                status: 'Статус обращения'
            },
            tbody: [
                {
                    id: 1,
                    dateOper: '12.03.2019',
                    fio: 'Куплинов Аркадий Витальевич',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'На рассмотрении',
                    class: 'default'
                },
                {
                    id: 2,
                    dateOper: '08.03.2019',
                    fio: 'Косухина Светлана Николаевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Принято фондом',
                    class: 'accepted'
                },
                {
                    id: 3,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 4,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 5,
                    dateOper: '14.02.2019',
                    fio: 'Абрамович Екатерина Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Принято фондом',
                    class: 'accepted'
                },
                {
                    id: 6,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 7,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 8,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 9,
                    dateOper: '07.03.2019',
                    fio: 'Клюква Елизавета Анатольевна',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отклонено',
                    class: 'rejected'
                },
                {
                    id: 10,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 11,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отправлено на доработку',
                    class: 'default'
                },
                {
                    id: 12,
                    dateOper: '08.03.2019',
                    fio: 'Перманентный Константин Константинович',
                    agent: 'ПАО Банк «Уралсиб»',
                    product: {
                        title: 'Дополнительные возможности',
                        desc: 'Индивидуальный пенсионный план'
                    },
                    appeal: 'Изменение персональных данных',
                    status: 'Отправлено на доработку',
                    class: 'default'
                }
            ]
        },
        table_client_list: {
            thead: {
                fio: 'ФИО клиента',
                mobile: 'Номер телефона',
                num_contract: 'Номер договора',
                pasport: 'Серия и номер паспорта',
                snils: 'СНИЛС',
                inn: 'ИНН'
            },
            tbody: [
                {
                    id: 1,
                    fio: 'Перманентный Константин Константинович',
                    mobile: '+7 (999) 213-29-20',
                    num_contract: '916–2871–981467215',
                    pasport: '1210 761238',
                    snils: '768-513-078 16',
                    inn: '776213189212',
                    class: 'default'
                },
                {
                    id: 2,
                    fio: 'Перманентный Константин Константинович',
                    mobile: '+7 (999) 213-29-20',
                    num_contract: '916–2871–981467215',
                    pasport: '1210 761238',
                    snils: '768-513-078 16',
                    inn: '776213189212',
                    class: 'default'
                },
                {
                    id: 3,
                    fio: 'Перманентный Константин Константинович',
                    mobile: '+7 (999) 213-29-20',
                    num_contract: '916–2871–981467215',
                    pasport: '1210 761238',
                    snils: '768-513-078 16',
                    inn: '776213189212',
                    class: 'default'
                },
                {
                    id: 4,
                    fio: 'Перманентный Константин Константинович',
                    mobile: '+7 (999) 213-29-20',
                    num_contract: '916–2871–981467215',
                    pasport: '1210 761238',
                    snils: '768-513-078 16',
                    inn: '776213189212',
                    class: 'default'
                },
                {
                    id: 5,
                    fio: 'Перманентный Константин Викторович',
                    mobile: '+7 (999) 213-29-20',
                    num_contract: '916–2871–981467215',
                    pasport: '1210 761238',
                    snils: '768-513-078 16',
                    inn: '776213189212',
                    class: 'default'
                }
            ]
        }
    },
    actions: {}, 
    mutations: {},
    getters: {
        getTable1(state){
            return state.table1
        },
        getTable2(state){
            return state.table2
        },
        getTableHistory(state){
            return state.table_history
        },
        getTableHistoryClient(state){
            return state.table_history_client
        },
        getTableClientList(state){
            return state.table_client_list
        }
    }
}