import {axJson} from './../__axInstance__'
import aggregateErrors from './../__axInstance__/aggregate_errors'
import Vue from 'vue'

export default {
    
    namespaced: true,
    state: {
        path: false,
        error: null,
    },

    actions: {
        async auth({ commit }, {params, setCookie}){

            setCookie = Vue.prototype.$cookies

            //9 и 73 страница в тз
            let errMessage = ''

            try{

                commit('clearError')

                params = { ...params, token: 'lgso0589klosfmbv-kwshsjkufwtghytwdjduike71zwj09f26rtnikkj98gr-32bdf37jkzb3qvb896eu79kbe4yf0o0mi7n6g54ec2k53fhgi00-8z21eg345h79oj4347fr' }

                const { data } = await axJson.post('?action=auth', params)
                

                switch(data.descr){

                    case 'empty_password':
                    case 'empty_token':
                    case 'empty_login':
                        errMessage = 'Ошибка: не заполнена часть данных, необходимых для авторизации'
                        break
                    
                    case 'user_not_found':
                        errMessage = 'Ошибка: агент с таким логином не найден'
                        break

                    case 'invalid_token':
                        errMessage = 'Ошибка: неверный токен авторизации'
                        break

                    case 'invalid_password':
                        errMessage = 'Ошибка: пароль Агента'
                        break
                    
                    case 'access_denied':
                        errMessage = 'Ошибка: указанный Агент не имеет прав доступа на работу в системе'
                        break
                    
                    case 'no_valid_agent_contract':
                        errMessage = 'Ошибка: отсутствует действующий агентский договор. Работа Агента не возможна'
                }
                
                const existErrors = aggregateErrors.call(this, { commit }, { data, localError: errMessage })


                if(!existErrors) return;
                
                commit('writeCookie', {setCookie, data})
                

            }catch(error){
                commit('viewError', { errorMessage: error })
            }

        }
    },
    mutations: {

        writeCookie(state, {setCookie, data}){


            //фиксируем фио
            const fio = JSON.stringify({
                                last_name: data.last_name, 
                                first_name: data.first_name, 
                                middle_name: data.middle_name
                            })

            setCookie.set('fio', fio);
            setCookie.set('name_host', data.nameHost);

            //далее выбирается самое высокое полномочие
            let allRoles = ['agent', 'manager', 'responsible'],
                role = {}

            //находим самое высокое полномочие
            let needRole = allRoles.reduce((acc, curr, index) => {
   
                data.roles.map((item) => {
                  
                  if(item === curr){
                    role = { name: item, index: index }
                  }
                })


               if(role.index > acc.index) return role
               return acc
               
             }, { name: 'agent', index: 0 })


            setCookie.set('role', needRole.name, {expires: '1d'});
            state.path = true

        },

        viewError(state, { errMessage }){
            state.error = errMessage
        },

        clearError(state){
            state.error = null
        }
        
        
    },
    getters: {
        
        currState(state){
            return state
        }

    }
}
