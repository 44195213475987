import {axJson} from './__axInstance__'
import currentDocId from './__axInstance__/current_doc_id'
import aggregateErrors from './__axInstance__/aggregate_errors'


export default {
    
    namespaced: true,
    state: {
        stage: 0,
        error: null
    },

    actions: {
        async check({ commit }){

            try{


                const { data } = await axJson.get(`ipo/contract_api?action=get_ipo_contract${currentDocId('contract')}`)

                const existErrors = aggregateErrors.call(this, { commit }, { data })

                const stage = data.doc_data?.stage

                if(!existErrors) return;


                if(!data.doc_data) return;

                commit('changeStage', { stage })

            }catch(error){
                console.dir(error)
            }


        },

    },
    mutations: {

        viewError(state, { errorMessage }){
            state.error = errorMessage
        },

        changeStage(state, { stage }){
            state.stage = stage
        }

    }
}
