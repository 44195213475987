<template>
    <div class="v-recovery">
        <div class="v-recovery-wrap area">
            <a href="/" class="v-recovery-logo">
                <img src="@/assets/img/svg/logo-big.svg" alt="">
            </a>
            <p class="v-recovery-title">Восстановление пароля</p>

            <div class="v-recovery-block">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-recovery'
}
</script>
