export default {
    
    namespaced: true,

    state: {
        view: false,
        name: null,
        postFunc: null,
        workPath: null
    },

    actions: {
        viewButton({ commit }, { name, postFunc, workPath, visible = false }){

            if(visible){
                commit('set', { name, postFunc, workPath })
            }

        },

        hiddenButton({ commit }){
            commit('unset')
        }
    },

    mutations: {

        set(state, { name, postFunc, workPath }){

            state.view = true
            state.name = name
            state.postFunc = postFunc
            state.workPath = workPath
        },

        unset(state){
            state.view = false
            state.name = null
            state.postFunc = null
            state.workPath = null
        },

    },

}
