import {axJson} from './../__axInstance__'
import currentDocId from './../__axInstance__/current_doc_id'
import aggregateErrors from './../__axInstance__/aggregate_errors'

export default {
    
    namespaced: true,

    state: {
        error: null,
        data_errors: null
    },

    actions: {
        async start({ commit }){

            try{

                let errMessage = ''

                const { data } = await axJson.post(`ipo/contract_api?action=finalize_ipo_contract${currentDocId('contract')}`)


                if(data.descr === 'denied_for_this_contract_stage'){

                    errMessage = `Ошибка финализации, данные по договору еще не подтверждены по СМС. Вызов метода 
                                финализаци договора возможно только для статуса stage=1`
                }

                const existErrors = aggregateErrors.call(this, { commit }, { data, localError: errMessage })

                if(!existErrors) return;
                
            }catch(errorMessage){
                commit('viewError', { errorMessage })
            }

        },

    },
    mutations: {

        viewError(state, { errorMessage }){
            state.error = errorMessage
        },

        viewDataError(state, { field_errors }){
            state.data_errors = field_errors
        },

    }
}
