import {axJson} from './__axInstance__'

export default {
    
    namespaced: true,
    state: {
        path: false,
        error: null
    },

    actions: {
        async filter(ctx, params){

            //16 и 62 страница в тз

            try{
                const {data} = await axJson.post('?action=get_ipo_contracts', params)

                ctx.commit('setData', { data })

            }catch(errMessage){
                ctx.commit('viewError', { errMessage })
            }

        }
    },
    mutations: {

        setData(state, { data }){
            state.data = data
        },

        viewError(state, { errMessage }){
            state.error = errMessage
        }
        
    },
    getters: {
        
        currState(state){
            return state
        }

    }
}
