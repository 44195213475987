<template>
  <div class="v-client-list grid-dashboard">


      <div class="v-client-list-top grid-item grid-dashboard">
            <div class="v-client-list-top-title main-title">
                <svg>
                    <use xlink:href="../../assets/img/sprite.svg#search-icon"></use>
                </svg>
                <p class="h2 type-1">Поиск клиента</p>
            </div>
            <div class="v-client-list-top-subtitle">
                <p class="h2 type-2">Найдено несколько похожих результатов</p>
            </div>
      </div>
      <div class="v-client-list-main grid-item grid-dashboard">
          <div class="v-client-list-notification grid-item">
              <div class="wrapper-notifications default">
                  <p>Выберите вручную подходящий результат из таблицы</p>
              </div>
          </div>
          
        <Tables 
            name="Поиск клиента"
            group="search"
            tableStyle="search_client"
            :inputsView="['status', 'operation']"

            :typeForm="'extended'"
            :mainData="clients"
            :downloadMore="() => {}"
            :payload="findClientPayload"
            :lastLoad="true"
            :rowsPaginator="3"
            :propFuncs="{ redirectToClient }"
        /> 


      </div>
  </div>
</template>


<script>

import Tables from '@/components/local/tables/index'
import { mapState } from 'vuex'

export default {

    name: 'v-client-list',
    
    computed: {


        ...mapState({

            findClientError:        state => state.findClient.error,
            findClientData_errors:  state => state.findClient.data_errors,
            findClientPath:         state => state.findClient.path,
            findClientData:         state => state.findClient.data,
            findClientPayload:      state => state.findClient.payload,

        //     rowsPaginator:       state => state.getStatementContracts.rowsPaginator,
        }),


    },

    data(){
        return {
            clients: []
        }
    },

    mounted(){
        this.getClients()
    },

    methods: {

        redirectToClient(item){

            this.$cookies.set('fio', item.fio)
            this.$cookies.set('last_found_client_one', JSON.stringify(
                {
                    dul_fio: item.fio,
                    passport_seriesnumber: item.passport,
                    birth_date: item.birth_date,
                    code: item.code
                }
            ))
            this.$router.push({ name: 'client', params: {id: item.code} })

        },
        
        async getClients(){

            await this.$store.dispatch('findClient/search', {
                params: this.$cookies.get('last_found_client_group')
            })

            const data = this.findClientData


            for(let person of data){
                

                this.clients.push({
                    id:           Date.now(),
                    fio:          person.full_name,
                    mobile:       person.phone,
                    birth_date:   person.birth_date,
                    code:         person.code,
                    passport:     person.passport_series_number,
                    snils:        person.snils,
                    inn:          person.inn,
                    contracts:    person.permissions.contracts
                })

            }

        },
    },

    components: {
        Tables
    }
}
</script>

<style>

</style>