<template>
  <div class="v-client grid-dashboard grid-column-2">

      <transition name="fade" appear>
          <div v-if="findClientData !== null" class="v-client-top grid-item">
                <div class="v-client-top-title main-title">
                    <svg>
                            <use xlink:href="../../assets/img/sprite.svg#user-icon"></use>
                        </svg>
                        <p class="h1">{{ findClientData.full_name }}</p>
                </div>
                <div class="v-client-top-data">
                    <div class="v-client-top-data-list">
                        <div class="v-client-top-data-item">

                            <p>Дата рождения:</p>
                            <span>
                                {{ findClientData.birth_date }}
                            </span>

                        </div>
                        <div class="v-client-top-data-item">
                            <p>Серия и номер паспорта:</p>
                            <span>{{ findClientData.passport_series_number | VMask('## ## ######') }}</span>
                        </div>
                        <div class="v-client-top-data-item">
                            <p>СНИЛС:</p>
                            <span>{{ findClientData.snils }}</span>
                        </div>
                        <div class="v-client-top-data-item">
                            <p>Мобильный телефон:</p>
                            <span>{{ findClientData.phone | VMask('+7 (###) ###-##-##') }}</span>
                        </div>
                    </div>
                </div>
            </div>
      </transition>
      <transition name="fade" appear>
           <div class="v-client-applications grid-item">
                <div class="v-client-applications-top">
                        <div class="v-client-applications-title main-title">
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#file-text-icon"></use>
                            </svg>
                            <p class="h2 type-2">Заявления</p>
                        </div>
                    </div>

                    <div v-if="findClientData && findClientData.permissions.contracts.length === 0" class="v-client-applications-main empty">
                        <div class="v-client-applications-main-empty">
                            
                            <p>К сожалению, клиент не может подать заявление, так как у его нет действующего договора.</p>
                            <p>Предложите клиенту оформить

                                <router-link 
                                    to="/contract_step/1"
                                    class="btn-big btn-text link-inline link-text"
                                >
                                    новый договор
                                </router-link>!
                            </p>
                        </div>
                    </div> 


                    <div v-if="findClientData && findClientData.permissions.contracts.length !== 0"
                         class="v-client-applications-main changing-data area"
                         :class="canStatement ? 'anyClass': 'disabled'"
                    >
                        <div class="v-client-applications-list grid-dashboard">
                            <div class="v-client-applications-item-full">
                                <div class="v-client-applications-item-full-text">
                                    <div class="v-client-applications-item-full-text-title">
                                        <p>Изменение персональных данных<br> и банковских реквизитов</p>
                                    </div>
                                    <div class="v-client-applications-item-full-text-list">
                                        <ul>
                                            <li>
                                                <span>
                                                    <svg>
                                                        <use xlink:href="../../assets/img/sprite.svg#product-changing-data-icon1"></use>
                                                    </svg>
                                                </span>
                                                <p>Если клиент поменял фамилию, получил новый паспорт или поменял адрес прописки, в течение 30 дней необходимо сообщить об этом фонду</p>
                                            </li>
                                            <li>
                                                <span>
                                                    <svg>
                                                        <use xlink:href="../../assets/img/sprite.svg#product-changing-data-icon2"></use>
                                                    </svg>
                                                </span>
                                                <p>Если клиент хочет получать пенсию на счет в другом банке, ему необходимо сообщить фонду новые реквизиты</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="v-client-applications-item-full-text-btn" ref="tippy_button" name="btn_getStatement">
                                        <button class="btn-middle btn-text secondary"
                                                @click="getStatement"
                                                :disabled="!canStatement"
                                            >
                                            Подать заявление


                                        </button>

                                    </div>
                                    <tippy to="btn_getStatement" followCursor="false" placement='top-end' theme='light' v-if="!canStatement">
                                        <div>Вы не можете подать заявление об изменении персональных данных, обратитесь в фонд</div>
                                    </tippy>
                                </div>
                                <div class="v-client-applications-item-full-icon">
                                    <img src="@/assets/img/svg/product-changing-date.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="typesCard.full" class="v-client-applications-main">
                    <div class="v-client-applications-list grid-dashboard grid-column-2">

                        <div v-if="viewStatements.pdbr" class="v-client-applications-item changing-data">

                            <div class="v-client-applications-item-title">
                                    <p>Изменение персональных данных и банковских реквизитов</p>
                            </div>
                            <div class="v-client-applications-item-btns">
                                
                                    <button class="btn-middle btn-text secondary"
                                                @click="getStatement"
                                            >
                                            Подать заявление
                                        </button>

                                <div>--</div>
                            </div>

                        </div>


                        <div v-if="viewStatements.ops" class="v-client-applications-item insurance">

                            <div class="v-client-applications-item-title">
                                    <p>Назначение пенсии обязательного пенсионного страхования</p>
                            </div>
                            <div class="v-client-applications-item-btns">
                                <button class="btn-middle btn-text secondary">Подать заявление</button>
                                <div>--</div>
                            </div>

                        </div>


                        <div v-if="viewStatements.npo" class="v-client-applications-item non-state-pension">

                            <div class="v-client-applications-item-title">
                                    <p>Назначение негосударственной пенсии</p>
                            </div>     
                            <div class="v-client-applications-item-btns">
                                <button class="btn-middle btn-text secondary">Подать заявление</button>
                                <div>--</div>
                                </div>          

                        </div>


                        <div v-if="viewStatements.op" class="v-client-applications-item decoration">

                            <div class="v-client-applications-item-title">
                                    <p>Оформление правоприемника</p>
                            </div>
                            <div class="v-client-applications-item-btns">
                                <button class="btn-middle btn-text secondary">Подать заявление</button>
                                <div>--</div>

                            </div>
                        </div>

                    </div>
                    </div>


            </div>
      </transition>
      <transition name="fade" appear>
           <div class="v-client-products grid-item">
                <div class="v-client-products-top">
                        <div class="v-client-products-title main-title">
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#credit-card-icon"></use>
                            </svg>
                            <p class="h2 type-2">Продукты</p>
                        </div>
                    </div>
                    <div class="v-client-products-main small-table-wrap">
                        
                        <div v-if="contracts.data.length" class="area small-table">

                            <div class="wrapper-table type-2">
                            <div class="table">
                                <div class="thead">
                                    <div class="tr">
                                        <div class="tr-wrap">
                                            <div class="th"></div>

                                            <div class="th">
                                                <p>Тип продукта</p>
                                            </div>
                                            <div class="th">
                                                <p>Открыт</p>
                                            </div>
                                            <div class="th">
                                                <p>Статус</p>
                                            </div>

                                            <div class="th"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tbody">

                                    <div 
                                        v-for="item of contracts.data.slice(0, contracts.viewCount)"
                                        :key="item.number"
                                        class="tr"
                                    >
                                        <div class="tr-wrap">
                                            <div class="td">
                                                <div class="v-table-icon-type2" v-if="item.type === 'NPO'">
                                                    <svg>
                                                        <use xlink:href="../../assets/img/sprite.svg#emblem-icon"></use>
                                                    </svg>
                                                </div> 
                                                <div class="v-table-icon-type2" v-if="item.type === 'OPS'">
                                                    <svg>
                                                        <use xlink:href="../../assets/img/sprite.svg#future-icon"></use>
                                                    </svg>
                                                </div>
                                            </div>

                                            <div class="td">
                                                <div class="td-text">
                                                    <p class="td-text__title">{{ item.product_name }}</p>

                                                    <!-- в каком случае показывать описание. Задать вопрос -->
                                                    <p class="td-text__desc">{{ item.desc }}</p>

                                                    <p class="td-text__account">{{ item.number }}</p>

                                                </div>
                                                
                                            </div>

                                            <div class="td">
                                                <p>{{ item.agr_date_signed }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.status }}</p>
                                            </div>

                                            <div class="td">
                                                <div class="table-more">
                                                    <svg>
                                                        <use xlink:href="../../assets/img/sprite.svg#more-vertical-icon"></use>
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>
                                    </div> 


                                </div>
                            </div>
                        </div>
                        </div>

                        <div v-if="!contracts.data.length" class="empty-contracts">
                            Нет действующих договоров
                        </div>
                        
                        <div class="area-buttons btns-end">
                            <div class="area-buttons-block">

                                <button 
                                        v-if="contracts.data.length > contracts.maxShort" 
                                        class="btn-middle btn-text secondary"
                                        @click="allContracts">
                                    {{ textButtonAllContracts }}
                                </button>

                                <button class="btn-middle btn-text primary"
                                        @click="() => { $router.push({ name: 'contractId-list' }); 
                                                        $cookies.set('last_press_btn_contract', 'client-btn')
                                        }">

                                    Новый договор
                                </button>

                            </div>
                        </div>
                    </div>
            </div>
      </transition>

      <transition name="fade" appear>
        <Tables name="История коммуникаций по договорам"
            group="contracts"
            typeForm="short"
            :mainData="contractData"
            :payload="contractPayload"
            tableStyle="short_agent_contract"
            :listFullPath="`history-client/${pathID}/contract`"
        />
      </transition>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tables from '@/components/local/tables/index'

export default {

    name: 'v-client',

    data(){

        const { client, pathID } = this.redirectFromStatement()

        const clientData = {
            mobile_phone: null,
            snils: null,
            dul_fio: null,
            num_doc: null,
            inn: null,
            birth_date: null,
            passport_seriesnumber: null,
        }

        return {
            pathID,
            // contracts: [],
            currPath: +this.$route.params.id,

            clientData: this.$fillActual(clientData, client, null),
            
            typesCard: {
                empty: false,
                one:   true,
                full:  false
            },

            viewStatements: {
                pdbr: true,
                ops: true,
                npo: true,
                op: true
            },
            
            contracts: {
                data: [],
                viewCount: 3,
                maxShort: 3,
                maxExtended: 5
            },

            canStatement: true,

            renderKey: 0,

        }

    },

    watch: {

        "findClientData"(){

            this.getProducts()
        }

    },
    
    //    ${this.contracts.viewCount !== this.contracts.maxExtended
    //                                         ? '('+ this.contracts.data.length +')' 
    //                                         : '' }`

    computed: {

        textButtonAllContracts(){
            return `Все договоры`
        },



        ...mapState({

            findClientData:         state => state.findClient.data,
            findClientError:        state => state.findClient.error,
            findClientData_errors:  state => state.findClient.data_errors,
            findClientPath:         state => state.findClient.path,

            contractData:       state => state.getStatementContracts.contractData,
            contractError:      state => state.getStatementContracts.contractError,
            contractPayload:    state => state.getStatementContracts.contractPayload,
        }),


    },

    async mounted(){

        const data = { ...this.clientData }

        data.birth_date = data.birth_date.replace(/\//g, '.')

        await this.$store.dispatch('findClient/search', {
            params: data
        })

        this.checkCanStatement()

        await this.$store.dispatch('getStatementContracts/get', {
            init: 'contract',
            type: 'short',
            role: 'client',
        })


        // фио нужно еще и для отображения его слева, в виде навигации
        this.$cookies.set('fio', this.findClientData.full_name)

    },

    methods: {

        getProducts(){

            const data = this.findClientData?.permissions?.contracts || []

            let dates = '',
                dateUnix = 0

            data.forEach(item => {
                
                if(typeof item?.agr_date_signed !== 'string'){
                    return;
                }

                dates = item.agr_date_signed.split('-')

                dateUnix = Date.UTC(...dates)

                item.agr_date_signed = new Date(dateUnix).toLocaleString('ru', { 
                                                                            year: 'numeric', 
                                                                            month: 'numeric', 
                                                                            day: 'numeric' 
                                                                        })

            })


            this.contracts.data = data
            // this.contracts.data = [...data, {}, {}, {}]
        },

        allContracts(){

            const { viewCount, maxShort, maxExtended } = this.contracts

            this.contracts.viewCount = (viewCount === maxExtended) 
                                                            ? maxShort 
                                                            : maxExtended

        },
        
        redirectFromStatement(){
            
            let client = this.$cookies.get('last_found_client_one'),
                pathID = +this.$route.params.id


            if(client?.code !== pathID){
                this.$router.push({ name: 'home' })
            }

            return {
                client,
                pathID
            }

        },

        errorsAPI({ error, data_errors }, nameForm){
            
            this.errorsSyntax({
                    serverError: [error],
                    fieldErrors: data_errors,
                    api: true
                }, nameForm);
        },

        errorsSyntax({ serverError = [], fieldErrors = {}, api = false }, nameForm){

            this.$formulate.handle({

                api: api,

                aggregate: fieldErrors,
                formErrors: serverError,

            }, nameForm)

        },

        getStatement() {
            if (!this.canStatement) {
                alert('Хитрый хакер, заявление нельзя подать поменяв стили у кнопки')
                return
            }
            
            this.$cookies.set('client_id', this.pathID)
            
            this.$router.push({ name: 'statementId-1'})
            
        },
        checkCanStatement() {
            
            if (this?.findClientData?.permissions.contracts.findIndex(item => { // ищет по всем пришедшему массиву договоров тот, в котором можно заменить какие-то реквизиты

                if (item.allow_br === true) {
                    return true
                }
                if (item.allow_pd === true) {
                    return true
                }
                return false
            }) !== -1)  {
                this.canStatement = true

            // добавил условие ниже. Была ошибка
            } else if(this.$refs.tippy_button){

                this.$refs.tippy_button.name = 'btn_getStatement'

                this.canStatement = false
            }
        }
        
    },

    components: {
        Tables
    }

}
</script>

<style>

</style>