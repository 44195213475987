<template>
    <header id="v-header" class="v-header">
        <div class="v-header-back-btn">
            <transition name="fade" appear>
                <button v-if="buttonView" 
                        @click="buttonFunc"
                        class="btn-small btn-text-icon link-inline"
                >
                    <span class="btn-icon">
                        <svg>
                            <use xlink:href="@/assets/img/sprite.svg#back-page-icon"></use>
                        </svg>
                    </span>
                    <span>{{buttonName}}</span>              
                </button>
            </transition>
        </div>
        <transition name="fade" appear>
            <div class="v-header-main">
                <!-- <div class="v-header-main-item v-header-main-sms">
                    <div class="v-header-main-sms-link">
                        <button>Отправить смс</button>
                    </div>
                    <div class="v-header-main-sms-dropdown">
                        <button>
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#arrow-btn-icon"></use>
                            </svg>
                        </button>
                    </div>
                </div> -->
                <div class="v-header-main-item v-header-main-calendar">
                <transition name="calendar">
                    <v-calendar :attributes='attrs' v-if="calendarVisible"></v-calendar>
                </transition>
                <button class="v-header-main-calendar__icon" @click="openCalendar">
                    <svg>
                    <use xlink:href="../../assets/img/sprite.svg#header-calendar-icon"></use>
                    </svg>
                </button>
                </div>
                <div class="v-header-main-item v-header-main-info">
                    <button class="v-header-main-info__wiki" name="v-header-main-info__wiki">
                    <tippy to="v-header-main-info__wiki" followCursor="false" placement='top-end' theme='light'>
                        <div>Какой-то текст</div>
                    </tippy>
                        <svg class="test-tippy">
                            <use xlink:href="../../assets/img/sprite.svg#header-wiki-icon"></use>
                        </svg>
                    </button>
                    <button class="v-header-main-info__push fill">
                        <svg>
                            <use xlink:href="../../assets/img/sprite.svg#header-push-icon"></use>
                        </svg>
                    </button>
                    <p>
                        <span>{{fioAgent}}</span>
                        <small>агент</small>
                    </p>
                    <button class="v-header-main-info__logout" @click="logOut">
                        <button>
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#header-logout-icon"></use>
                            </svg>
                        </button>
                    </button>
                </div>
            </div>
        </transition>
    </header>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    name: 'v-header',

    data() {

      return {
        calendarVisible: false,
        fioAgent: '',
        attrs: [
          {
            key: 'today',
            highlight: true,
            dates: new Date(),
          },
        ]

      }
    },

    computed: {
        ...mapState({
            buttonView: state => state.viewLeftButtonHeader.view,
            buttonName: state => state.viewLeftButtonHeader.name,
            buttonFunc: state => state.viewLeftButtonHeader.postFunc,
            buttonWorkPath: state => state.viewLeftButtonHeader.workPath
        })
    },
    watch: {
        '$route.name': function(){

            const currPath = this.$route.name

            if(currPath !== this.buttonWorkPath){
                this.$store.dispatch('viewLeftButtonHeader/hiddenButton')
            }

        }
    },
    methods: {
        ...mapActions({
            deAuth: 'deAuth/deAuth',
        }),
        openCalendar() {
            this.calendarVisible = !this.calendarVisible;
        },
        hideCalendar() {
            this.calendarVisible = false
        },
        createAgentFio() {
        const fioAgentFull = this.$cookies.get('fioAgent')
        const first_name_agent_initial = fioAgentFull.first_name[0] + '.'
        const last_name_agent_full = fioAgentFull.last_name
        const middle_name_agent_initial = fioAgentFull.middle_name === '' ? '' :fioAgentFull.middle_name[0] + '.'
        this.fioAgent = `${last_name_agent_full} ${first_name_agent_initial} ${middle_name_agent_initial}`
        },
        logOut() {
            this.deAuth()
        }
    },
    mounted() {
        this.createAgentFio()
        document.addEventListener('click', (e) => {
            if (this.calendarVisible) {
                if(!e.target.closest('.v-header-main-calendar')) {
                    this.calendarVisible = false
                }
            }
        });
    },
}
</script>
