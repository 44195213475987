<template>
  <div class="v-sidebar">
      <transition name="fade" appear>
          <div class="v-sidebar-block">
                <div class="v-sidebar-logo">
                    <a href="/">
                        <img src="@/assets/img/svg/logo.svg" alt="">            
                    </a>
                </div>
                <div class="sidebar">
                    <ul>
                        <li :class="activeElement  === 'home' && 'active'">
                            <router-link to="/home">
                                <svg>
                                    <use xlink:href="../../assets/img/sprite.svg#sidebar-search-client"></use>
                                </svg>
                                <p>Поиск клиента</p>
                            </router-link>
                        </li>
                        
                        <li :class="activeElement === 'contract_step' && 'active'"
                             @click="$cookies.set('last_press_btn_contract', 'sidebar-btn')"
                        >
                            <router-link to="/contract_step/list">
                                <svg>
                                    <use xlink:href="../../assets/img/sprite.svg#sidebar-new-client"></use>
                                </svg>
                                <p>Новый клиент</p>
                            </router-link>
                        </li>
                        <li :class="activeElement === 'drafts' && 'active'">
                            

                            <a @click.prevent="setPathToVisibleDrafts" style="cursor: pointer;">
                                <svg>
                                    <use xlink:href="../../assets/img/sprite.svg#sidebar-draft"></use>
                                </svg>
                                <p>Черновики</p>
                            </a>

                        </li>
                    </ul>
                </div>
          </div>
      </transition>
  </div>
</template>

<script>
export default {

    name: 'v-sidebar',

    data(){
        return {
            activeElement: ''
        }
    },

    watch: {

        "$route.path": function(){

            this.getActiveNavLink()

        }

    },

    mounted(){
        this.getActiveNavLink()
    },

    methods: {
        
        // для работы требуется кука last_found_client
        setPathToVisibleDrafts(){

            const route = this.$route

            if(!route.path.includes('drafts')){

                if(route.fullPath.includes('client')){

                    this.$router.push({ name: 'draftsClient', params: { id: route.params.id } })
                    return;
                }

                this.$router.push({ name: 'drafts' })

            }

        },

        getActiveNavLink(){

            this.activeElement = ['home', 'contract_step', 'drafts'].find(route => {

                return this.$route.path.includes(route)
            })
            
        }

    }

}
</script>

