

export function getFields(data){

    let fieldDesc = {},
        textError = '',
        typeError = ''

    for(let field in data.field_errors){

        typeError = data.field_errors[field]

        textError = (typeError === 'invalid')
            ?
            "Некорректное значение параметра"
            :
            "Обязательный параметр не передан или передан пустым"

        //кастомные ошибки из тз
        if(typeError === 'invalid'){

            switch(field){

                case 'birth_date':
                    textError = 'Клиенту менее 18 лет'

            }

        }

        fieldDesc[field] = [textError]

    }

    return fieldDesc
}


export function getIdents(data){

    let identDesc  = '',
        list_error = {
            person_in_stop_list:  "Клиент находится в списке Экстремистов/Отказников/ФРОМУ/ПДЛ/ИПДЛ. СООБЩИМ Вы можете узнать больше, когда посетите наш офис.",
            smev_mvd:             "Паспортные данные клиента не прошли проверку в сервисе СМЭВ МВД (связка ФИО + дата рождения + серия/номер паспорта). СООБЩИМ В указанных паспортных данных возможно присутствует ошибка.",
            offline_mvd:          "Паспортные данные клиента не прошли проверку по справочной базе МВД. СООБЩИМ В указанных паспортных данных возможно присутствует ошибка.",
            passport_outdated:    "Паспорт должен быть заменен в 20 и 45 лет. В случае если это не так (определяется по дате выдачи и возрасту), будет выдана ошибка.",
            passport_ocr_check:   "Имя, отчество, серия + номер паспорта на скане документа не совпадают с введенными клиентом данными.",
            smev_pfr:             "Снилс клиента не соответствует указанным ФИО и дате рождения (СМЭВ ПФР). СООБЩИМ В указанных данных возможно присутствует ошибка.",
            smev_fns:             "Фио + снилс + паспорт клиента не прошли проверку в сервисе Минсвязи «Упрощенная идентификация»",
            smev_uprid:           "Фио + паспорт + инн клиента не прошли проверку в сервисе СМЭВ ФНС",
            identification_in_process: "Процесс идентификации не завершен (не все необходимые проверки пройдены)."
        }

    data?.identification_errors?.map((ident) => {

        for(let cretery in list_error){

            if(cretery === ident){
                identDesc = list_error[cretery]
                break
            }
        }
    })

    return identDesc
}

export function getSystem(data){
    console.log(data)
    let error = ''

    //contracts
    switch(data.descr){

        case 'invalid_request':
            error = 'Отсутствует тело http запроса'
            break

        case 'invalid_json':
            error = 'Ошибка парсинга json'
            break

        case 'undefined_action':
            error = 'В переменной action указано действие, неподдерживаемое api'
            break

        case 'server_not_responding':
            error = 'База или сервер обрабатывающий запросы недоступен'
            break

        case 'script_error':
            error = 'Ошибка скрипта'
            break

        case 'contract_not_found':
            error = 'Ошибка: отсутствует договор'
            break

        case 'already_confirmed':
            errMessage = 'Номер телефона уже подтвержден'
            break


    }


    //statements
    switch(data.descr){

        case 'not_found':
            error = data.message
            break
        case 'no_available_action':
            error = data.message
            break
    }

    console.log(error)
    return error
}
