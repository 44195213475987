import {axJson} from './__axInstance__'

export default {
    
    namespaced: true,

    actions: {
        async sendLoging({commit}, {params}){

            //81 страница в тз
            let errMessage = ''

            try{
                // const { data } = await axJson.post('?action=loging', params)

            }catch(error){

                commit('viewError', {
                    errMessage: 'Ошибка '+error
                })
            }

        }
    },
    mutations: {

        viewError(state, {errMessage}){
            state.error = errMessage
        }
        
    },
    getters: {
        
        currState(state){
            return state
        }

    }
}
