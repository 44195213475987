import {axJson} from './../__axInstance__'
import currentDocId from './../__axInstance__/current_doc_id'
import Vue from "vue";

export default {
    
    namespaced: true,
    state: {
        errors: null,
        data: {}
    },

    actions: {
        async get({commit}, { 
                            params, 
                            itemFind = 'id',
                            init = 'contract',
                    }
        ){

            let errMessage = '',
                   request = '',
                   drop    = ''

            try{
                switch(init){

                    case 'contract':
                        drop = 'statement'
                        break

                    case 'statement':
                        drop = 'contract'
                        
                }


                switch(itemFind){

                    case undefined:
                    case 'id':
                        params  = null
                        request = `ipo/contract_api?action=get_ipo_contract${currentDocId(init)}`
                        break

                    case 'name':
                        request = 'ipo/contract_api?action=get_ipo_contract'
                }
                
                const {data} = await axJson.get(request, { params })


                const { id } = data?.doc_data || {}

                if(id){
                    Vue.prototype.$cookies.set(`id_current_${init}`, id)
                }
               
                commit('setData', { data: data })


            }catch(error){
                console.dir(error)
            }

        },


    },
    mutations: {

        setData(state, data){
            state.data = data
        },

        viewError(state, errMessage){
            state.errors = errMessage
        },

    },
    getters: {
        
        currState(state){
            return state
        }

    }
}
