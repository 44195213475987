import $ from "jquery";
import suggestions from 'suggestions-jquery' //нужен для jquery
import Vue from "vue";

export default {

    state: {
        oneAuto: null,
        lastRefs: null,
        update: false
    },

    actions: {

        dadataHelper({ commit, state }, { refInputs, init, collabs, target }){

            let input    = null,
                nameHelp = ''

            commit('clearRefs')

            for(let ref of refInputs){

                if(typeof ref === 'undefined') { // для обработки форм, где блоки пропадают через v-if
                    continue;
                }

                if(ref.tagName === 'FORM') continue;

                input = ref.$el.querySelector('input')
                nameHelp = input.getAttribute('name-help')
                
                if(!nameHelp) continue;

                $(input).suggestions({

                    
                    token: "45976a0b3a13f6e53617076332cc2c58ae0e4fab",
                    type:  nameHelp,
                    // bounds: 'city-settlement-street-house',                    
                    
                    onSelectNothing(){

                        selected.call(this, state.oneAuto)
                        
                    },
                    onSelect(suggestion) {

                        selected.call(this, suggestion)

                    },

                    onSearchComplete(value, suggestion){

                        commit('setOneAuto', suggestion[0])

                    },

                    formatResult(value, currentValue, suggestion) {

                        const freeGroup = input.getAttribute('freeGroup'),
                                  field = suggestion.data[freeGroup] || ''

                        return (field === value) ? field : `${field} — ${value}`
                    }
                })


            }

            commit('setRefs', { refInputs })


            const hiddenFieldsForRequest = function(prop, suggestion){


                let theme = ''

                switch(prop){

                    case 'location_address':
                        theme = 'location'
                        break

                    case 'registration_address':
                        theme = 'registration'
                }

                if(!theme) return;

                const { fias_code, kladr_id, postal_code } = suggestion.data
                target.map(group => {
                    group[`${theme}_kladr`] = kladr_id
                    group[`${theme}_fias`] = fias_code
                    group[`${theme}_index`] = postal_code
                    group[`${theme}_json`] = suggestion
                })

            }

            function selected(suggestion){

                if(!suggestion) return;

                const { street, house, flat } = suggestion.data,
                            namePlace = this.getAttribute('name'),
                            addresses = {
                                full: suggestion.value,
                                street,
                                house,
                                flat
                            }

                const hasAddress = Vue.prototype.$cookies.get(namePlace) || '{}'
                
                const splitAddress = addresses.full.split(',')
                    

                splitAddress.forEach(item => {


                    if(item.includes(street)){

                        addresses.street = item

                    }else if(item.includes(house)){

                        addresses.house = item

                    }else if(item.includes(flat)){

                        addresses.flat = item
                    }

                })


                Vue.prototype.$cookies.set(namePlace, hasAddress ? { ...hasAddress, ...addresses } : addresses)
                
                    

                const freeGroup = this.getAttribute('freeGroup')
                let nameGroup = null

                if(this.hasAttribute('group_1')){

                    nameGroup = 'group_1'

                }else if(this.hasAttribute('group_2')){

                    nameGroup = 'group_2'

                }

                if(nameGroup) {
                    forCollab(refInputs, collabs, suggestion, nameGroup)

                }else{
                    forFreeField.call(this, {init, target, suggestion, freeGroup })
                }

                commit('update')
            }


            function forFreeField({ target, suggestion, freeGroup }){

                for(let group of target){
                    for(let prop in group){
                        if(prop === this.name){
                            this.value = group[prop]
                                       = suggestion.data[freeGroup]?.replace('-', ' ') || suggestion.value

                            //this.value = suggestion.value
                            //target[2].address = suggestion.value
                            //console.log(target[2].address)
                            hiddenFieldsForRequest(prop, suggestion)
                            return;
                        }

                    }
                }
            }

            function forCollab(refInputs, collabs, suggestion, nameGroup){
                let field = '',
                     find = {},
                     input = null

                for(let group in collabs){
                    if(group !== nameGroup) continue;
                    for(let elem in collabs[group]){
                        find = refInputs.find(ref => {
                            field = ref.$el.querySelector('input')?.getAttribute('name')
                            if(field === elem) return true;
                        })
                        if(!find) return;
                        input = find.$el.querySelector('input')
                        field = input.getAttribute(nameGroup)
                        input.value = collabs[group][elem]
                                    = suggestion.data[field]?.replace('-', ' ') || suggestion.value
                    }
                }

            }
        },

    },
    mutations: {

        clearRefs(state){
            state.lastRefs = null
        },
        
        setRefs(state, { data }){
            state.lastRefs = data
        },

        setOneAuto(state, suggestion){

            state.oneAuto = suggestion

        },

        update(state){

            state.update = Date.now()
        }

    }

}
