<template>
    <div class="v-contract-steps grid-dashboard">
        <transition name="fade" appear>
            <div class="v-contract-steps-top grid-item grid-dashboard">
                <div class="v-contract-steps-top-title main-title grid-item">
                    <svg v-if="currTexts.titleIcon">
                    <use xlink:href="../../assets/img/sprite.svg#user-icon"></use>
                    </svg>
                    <p class="h2 type-1">{{ currTexts.title }}</p>
                </div>
                <div class="v-contract-steps-top-subtitle main-subtitle grid-item">
                    <p class="h2 type-2">{{ currTexts.subtitle }}</p>
                </div>
            </div>
        </transition>
            <div class="v-contract-steps-main grid-item">
                <router-view :handleSubmit="handleSubmit" 
                        :setDadataHelper="setDadataHelper"
                        :getContract="getContract"
                        :key="renderKey"
                        :clearProjectSms="clearProjectSms"
                        v-if="renderKey !== 1"
            />
            </div>   
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'v-contract-steps',

    watch: {
        "$route.name": function () {

            this.getData();
            this.changeTopText(); 

        }
    },

    created(){
        
        this.getData()
    },

    data(){

        const fioCook = this.$cookies.get('last_found_client_one')?.dul_fio.toLowerCase()

        this.texts = {

            oldClient: {
                title: this.$lodash.startCase(fioCook),
                subtitle: 'Оформление нового договора',
                titleIcon: true
            },

            newClient: {
                title: 'Новый клиент',
                subtitle: 'Оформление нового договора',
                titleIcon: true
            },

            persPens: {
                title: 'Индивидуальный пенсионный план',
                subtitle: 'Вторая пенсия для вашего будущего',
                titleIcon: false
            }
        }

        return {
            renderKey: 1,
            fio: this.$cookies.get('fio') || {},

            currTexts: this.texts.persPens,

        }
    },

    computed: {
        ...mapState({
            bitContract: state => state.addBitContract,
            getContract: state => state.getExistContract.data
        }),
      currentRouteName() {
          // return this.$route.name;
          console.log(this.$route.name);
      }
    },

    mounted() {
        this.changeTopText();
    },




    methods: {
        
        async clearProjectSms(){

            await this.$store.dispatch('sendSms/clearProjectSms', { init: 'contract' })

        },

        async getData(){

            this.renderKey = 1

            await this.$store.dispatch('getExistContract/get', {
                                                setCookie: this.$cookies
                                            })
            this.renderKey = Date.now()
        },

        async handleSubmit({
                            data, 
                            nextPath = null, 
                            childFunc = () => {},
                            myselfRedirect = false
                        }){

            //console.log(nextPath)                
            //делаем запрос к api
            await this.$store.dispatch('addBitContract/add', {
                                        params: data,
                                        init: 'contract'
                                    })

            //выполняем функцию с действиями, необходимые
            //для текущего обрабатываемого шага
            await childFunc(this.bitContract)

            //console.log(this.bitContract)
            //если запрос не выдал ошибок, и шаг разрешает, то переходим далее
            if(this.bitContract.path && !myselfRedirect){
                this.$router.push({ name: nextPath })
            }

            return this.bitContract



        },
        
        setDadataHelper(refs,  { collabs, target, init }){

            this.$store.dispatch('dadataHelper', {
                    refInputs: Object.values(refs),
                    init,
                    target,
                    collabs
                })
        },

        changeTopText() {

            const lastBtn = this.$cookies.get('last_press_btn_contract')

            if(this.$route.name === 'contractId-list') {

                this.currTexts = this.texts.persPens

            }else if(this.$route.path.includes('contract_step') ){

                this.currTexts = (lastBtn === 'client-btn') 
                                            ? this.texts.oldClient
                                            : this.texts.newClient

            }
        }
    }
}
</script>
