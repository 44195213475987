import { axJson } from './../__axInstance__'

export default {
    
    namespaced: true,

    state: {
        data: null
    },

    actions: {

        async get({ commit }){


            const { data } = await axJson.get(`/ipo/contract_api?action=get_product_list`)

            // delete data.nameHost

            commit('setData', data)

        }
    },
    mutations: {

        setData(state, data){
            state.data = data
        },

    },

}
