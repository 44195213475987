<template>
    <div class="v-main-wrapper">
        <router-view />
    </div>
</template>
<script>

import { mapState } from 'vuex'
import Popup from '@/components/local/v-popup.vue'

export default {
    name: 'v-main-wrapper',

    watch: {
        'viewErrorIdent': function(){

            this.$modal.show(Popup, {
                title: this.errorTitle,
                text: this.errorText
            })
        }
    },

    computed: {
        ...mapState({
            viewErrorIdent: state => state.modalForErrorIdent.view,
            errorTitle: state => state.modalForErrorIdent.errorTitle,
            errorText: state => state.modalForErrorIdent.errorText
        })
    },
}
</script>
