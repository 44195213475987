<template>
    <div class="v-main-layout">
        <v-header />
        <v-sidebar />
        <v-body />
    </div>
</template>
<script>

import vHeader from '../elements/v-header'
import vSidebar from '../elements/v-sidebar'
import vBody from './v-body'

export default {
    name: 'v-main-layout',
    components: {
        vHeader,
        vSidebar,
        vBody
    }
}
</script>