export default {
    
    namespaced: true,

    state: {
        view: 0,
        errorTitle: '',
        errotText: ''
    },

    actions: {
        open({ commit }, data){
            commit('view', data)
        },

    },

    mutations: {

        view(state, { title, text }){
            state.view = Date.now()
            state.errorTitle = title
            state.errorText = text
        }

    }


}
