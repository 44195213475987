<template>
    <div id="app" v-cloak ref="main">
      <div 
          v-if="!showMobile"
          class="app-desktop"
      >
          <v-main-wrapper/>
          <fLogger :refs="$refs" :route="$route" />
      </div>
      <div 
          v-else
          class="app-mobile"
      >
        <div class="app-mobile-wrap">
            <div class="app-mobile-block">
                <div class="app-mobile-top">
                    <div class="app-mobile-img">
                        <img src="@/assets/img/svg/mobile-icon.svg" alt="">
                    </div>
                    <div class="app-mobile-title">
                        <p>Ваше устройство не поддерживается</p>
                    </div>
                </div>                
                <div class="app-mobile-text">
                    <p>Войдите в личный кабинет с помощью стационарного компьютера или ноутбука</p>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>

import vMainWrapper from './components/layouts/v-main-wrapper'
import fLogger from './components/functional/f-logger'

export default {
    name: 'app',
    data() {
      return {
        showMobile: false
      }
    },
    components: {
        vMainWrapper,
        fLogger
    },
    mounted() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // код для мобильных устройств
            this.showMobile = true;
        } else {
            // код для обычных устройств
            this.showMobile = false;
        }      
    }
}
// 1 версия  beforeDestroy
// const mediaQuery = window.matchMedia("(max-width:1024px)");
// this.showMobileMenu = mediaQuery.matches;
// const listener = e => this.showMobileMenu = e.matches;
// mediaQuery.addListener(listener);
// this.$once('hook:beforeDestroy', () => mediaQuery.removeListener(listener));

</script>


<style lang="scss">
@import "@/styles/setup/_variables.scss";

[v-cloak] { 
    display:none; 
} 
.app-desktop {
    min-width: 1024px;
}
.app-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 16px;
    }
    &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 343px;
    }
    &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 110px;
    }
    &-img {
        width: 116px;
        height: 186px;
        min-width: 116px;
        margin-bottom: 32px;
    }
    &-title {
        font-size: 36px;
        line-height: 44px;
        opacity: .5;
        color: $text-medium;
        text-align: center;
        width: 100%;
        max-width: 315px;
    }
    &-text {
        font-size: 18px;
        line-height: 23px;
        color: $text-main;
        text-align: center;
    }
}
@media (max-height: 550px) and (max-width: 1024px) {
    
    .app-mobile-top {
        margin-top: 20px;
        margin-bottom: 48px;
    }
    .app-mobile-text {
        margin-bottom: 20px;
    }
}
@media (max-height: 460px) and (max-width: 1024px) {
    .app-mobile-wrap {
        display: block;
        margin: auto;
    }
    .app-mobile-block {
        margin: auto;
    }
}


</style>
