import {axJson} from './../__axInstance__'
import aggregateErrors from './../__axInstance__/aggregate_errors'
import { clear } from './../__axInstance__/cleaners'
import Vue from 'vue'

export default {
    
    namespaced: true,
    state: {
        data: null,
        path: null,
        data_errors: null,
        error: null,
        payload: null
    },
 
    actions: {
        async search({ commit }, { params }){


            let errMessage = ''

            commit('clear')

            params = clear(params, 'old').personal_data


            try{
                const {data} = await axJson.get(`/afo/pdbr/find_client`, { params })


                if(data.descr === 'empty_query'){
                    errMessage = data.message
                }


                const existErrors = aggregateErrors.call(this, { commit }, { localError: errMessage })

                if(!existErrors) return;

                if(existErrors){

                    let length = data.clients.length,
                        nextPath = ''

                    if(length === 1){

                        const { code, full_name, birth_date, passport_series_number } = data.clients[0]

                        nextPath = `/client/${code}`
                        

                        Vue.prototype.$cookies.set('last_found_client_one', JSON.stringify({
                                                                dul_fio: full_name,
                                                                passport_seriesnumber: passport_series_number,
                                                                birth_date,
                                                                code
                                                            }))
                                                        
                        commit('setData', { data: data.clients[0]})

                    }else if(length > 1){

                        nextPath = '/client-list'

                        Vue.prototype.$cookies.set('last_found_client_group', JSON.stringify({
                            ...params, 
                            // code
                        }))

                        commit('setData', { data: data.clients })
                        
                    }else if(!length){

                        commit('viewError', { 
                                    errMessage: `По указанным параметрам в АС Фонда не найдены Договоры. Проверьте корректность ввода. 
                                        Если параметры поиска введены верно, клиенту необходимо обратиться в ПФР для уточнения текущего страховщика по обязательному 
                                        пенсионному страхованию или обратиться на горячую линию Фонда для уточнения информации по договору НПО` 
                        })
                    }

                    
                    commit('nextPath', { path: nextPath})
                    commit('payload')
                }

                
            }catch(error){
                commit('viewError', { errMessage: error })
            }

        },


    },
    mutations: {

        setData(state, { data }){

            state.data = data
        },

        nextPath(state, { path }){
            state.path = path
        },

        viewDataError(state, { field_errors }){
            state.data_errors = field_errors
        },

        viewError(state, { errMessage }){
            state.error = errMessage
        },

        clear(state){
            state.path = false
            state.data_errors = null
            state.error = null
            state.payload = false
        },

        payload(state){
            state.payload = true
        },

    },

}
