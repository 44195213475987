<template>
  <div class="v-uikit">
      <div class="v-uikit-area buttons">
          <p class="v-uikit-title">Buttons</p>
          <div class="v-uikit-area-block big">

              <div class="v-uikit-area-item text">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-big btn-text primary">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text primary disabled">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text primary preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-big btn-text secondary">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text secondary disabled">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text secondary preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-big btn-text border">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text border disabled">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text border preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-big btn-text link">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text link disabled">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text link preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-big btn-text link-inline">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text link-inline disabled">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text link-inline preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
              </div>
               <div class="v-uikit-area-item icon-text">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-big btn-text-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-big btn-text-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-big btn-text-icon border">
                       <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-big btn-text-icon link">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-big btn-text-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-big btn-text-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
              </div>
              <div class="v-uikit-area-item icon">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-big btn-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-big btn-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-big btn-icon border">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-big btn-icon link">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-big btn-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-big btn-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
              </div>

          </div>

          <div class="v-uikit-area-block middle">

            <div class="v-uikit-area-item text">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-middle btn-text primary">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text primary disabled">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text primary preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-middle btn-text secondary">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text secondary disabled">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text secondary preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-middle btn-text border">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text border disabled">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text border preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-middle btn-text link">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text link disabled">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text link preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-middle btn-text link-inline">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text link-inline disabled">
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text link-inline preload">
                        <span class="btn-icon">
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
              </div>
              <div class="v-uikit-area-item icon-text">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-middle btn-text-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-middle btn-text-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-middle btn-text-icon border">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-middle btn-text-icon link">
                       <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-middle btn-text-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-middle btn-text-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
              </div>
              <div class="v-uikit-area-item icon">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-middle btn-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-middle btn-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-middle btn-icon border">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-middle btn-icon link">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-middle btn-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-middle btn-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
              </div>
          </div>

          <div class="v-uikit-area-block small">

                <div class="v-uikit-area-item text">
                    <div class="v-uikit-area-item-wrap primary">
                        <button class="btn-small btn-text primary">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text primary disabled">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text primary preload">
                            <span class="btn-icon">
                                <span class="btn-preload"></span>
                            </span>
                            <span>Button</span>
                        </button>
                    </div>
                    <div class="v-uikit-area-item-wrap secondary">
                        <button class="btn-small btn-text secondary">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text secondary disabled">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text secondary preload">
                            <span class="btn-icon">
                                <span class="btn-preload"></span>
                            </span>
                            <span>Button</span>
                        </button>
                    </div>
                    <div class="v-uikit-area-item-wrap border">
                        <button class="btn-small btn-text border">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text border disabled">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text border preload">
                            <span class="btn-icon">
                                <span class="btn-preload"></span>
                            </span>
                            <span>Button</span>
                        </button>
                    </div>
                    <div class="v-uikit-area-item-wrap link">
                        <button class="btn-small btn-text link">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text link disabled">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text link preload">
                            <span class="btn-icon">
                                <span class="btn-preload"></span>
                            </span>
                            <span>Button</span>
                        </button>
                    </div>
                    <div class="v-uikit-area-item-wrap link-inline">
                        <button class="btn-small btn-text link-inline">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text link-inline disabled">
                            <span>Button</span>
                        </button>
                        <button class="btn-small btn-text link-inline preload">
                            <span class="btn-icon">
                                <span class="btn-preload"></span>
                            </span>
                            <span>Button</span>
                        </button>
                    </div>
                </div>
                <div class="v-uikit-area-item icon-text">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-small btn-text-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-small btn-text-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-small btn-text-icon border">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-small btn-text-icon link">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-small btn-text-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                    <button class="btn-small btn-text-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                        <span>Button</span>
                    </button>
                </div>
                </div>
                <div class="v-uikit-area-item icon">
                <div class="v-uikit-area-item-wrap primary">
                    <button class="btn-small btn-icon primary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon primary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon primary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap secondary">
                    <button class="btn-small btn-icon secondary">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon secondary disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon secondary preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap border">
                    <button class="btn-small btn-icon border">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon border disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon border preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link">
                    <button class="btn-small btn-icon link">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon link disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon link preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
                <div class="v-uikit-area-item-wrap link-inline">
                    <button class="btn-small btn-icon link-inline">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon link-inline disabled">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                    <button class="btn-small btn-icon link-inline preload">
                        <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                    </button>
                </div>
              </div>

          </div>
      </div>

       <div class="v-uikit-area inputs">
          <p class="v-uikit-title">Inputs</p>
          <div class="v-uikit-area-block big">
              <div class="v-uikit-area-item">
                  <div class="v-uikit-area-item-wrap">
                     <div class="wrapper-input big">
                            <FormulateInput
                                type="text"
                                name="num_contract"
                                label="Номер договора"
                                v-changeFocusBlur
                            />
                        </div>
                  </div>
              </div>
          </div>
          <!-- <div class="v-uikit-area-block small">
              <div class="v-uikit-area-item">
                  <div class="v-uikit-area-item-wrap">
                      gknl;sdfkndlfkn
                  </div>
              </div>
          </div> -->
      </div>

      <div class="v-uikit-area tables">
          <p class="v-uikit-title">Tables</p>
          <div class="v-uikit-area-block">
              <div class="v-uikit-area-item area small-table">

                  <div class="wrapper-table type-1">
                      <div class="table">
                          <div class="thead">
                              <div class="tr">
                                  <div class="tr-wrap">
                                    <div class="th">
                                        <p>{{ getTable1.thead.dateOper }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.agent }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.thing }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.status }}</p>
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="tbody">
                              <div 
                                v-for="item_tr in getTable1.tbody"
                                :key="item_tr"
                                :class="'tr ' + item_tr.class"
                              >
                                  <div class="tr-wrap">

                                    <div class="td">
                                        <p>{{ item_tr.dateOper }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.agent }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.appeal }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.status }}</p>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
          <!-- <div class="v-uikit-area-block type-2">
              <div class="v-uikit-area-item">

                  <div class="wrapper-table">
                      <div class="table">
                          <div class="thead">
                              <div class="tr">
                                  <div class="tr-wrap">
                                    <div class="th">
                                        <p>{{ getTable1.thead.dateOper }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.agent }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.thing }}</p>
                                    </div>
                                    <div class="th">
                                        <p>{{ getTable1.thead.status }}</p>
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="tbody">
                              <div 
                                v-for="item_tr in getTable1.tbody"
                                :key="item_tr"
                                class="tr"
                              >
                                  <div class="tr-wrap">
                                      
                                    <div class="td">
                                        <p>{{ item_tr.dateOper }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.agent }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.appeal }}</p>
                                    </div>
                                    <div class="td">
                                        <p>{{ item_tr.status }}</p>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div> -->
      </div>

      <div class="v-uikit-area check">
          <p class="v-uikit-title">Checkbox / Swith</p>
          <div class="v-uikit-area-block">
              <div class="v-uikit-area-item">
                  <div class="wrapper-checkbox inline">
                      <label class="checkbox">
                          <input type="checkbox">
                          <div></div>
                          <p>Checkbox</p>
                      </label>
                  </div>
                  <div class="wrapper-checkbox inline disabled">
                      <label class="checkbox">
                          <input type="checkbox">
                          <div></div>
                          <p>Checkbox disabled</p>
                      </label>
                  </div>
              </div>
              <div class="v-uikit-area-item">
                  <div class="wrapper-checkbox inline-block">
                      <label class="checkbox">
                          <input type="checkbox">
                          <div></div>
                          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius similique veniam velit delectus. Commodi eaque quos sed obcaecati qui voluptatum? Iste labore atque a voluptates obcaecati quia fugit assumenda consequuntur. </p>
                      </label>
                  </div>
                  <div class="wrapper-checkbox inline-block disabled">
                      <label class="checkbox">
                          <input type="checkbox">
                          <div></div>
                          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius similique veniam velit delectus. Commodi eaque quos sed obcaecati qui voluptatum? Iste labore atque a voluptates obcaecati quia fugit assumenda consequuntur.</p>
                      </label>
                  </div>
              </div>
              <div class="v-uikit-area-item">
                <div class="wrapper-switch">
                  <label>
                    <input type="checkbox">
                    <div></div>
                    <p>Switch</p>
                  </label>
                </div>
                 <div class="wrapper-switch disabled">
                  <label>
                    <input type="checkbox">
                    <div></div>
                    <p>Switch disabled</p>
                  </label>
                </div>
              </div>
          </div>
      </div>

      <div class="v-uikit-area notifications">
          <p class="v-uikit-title">Notifications</p>
          <div class="v-uikit-area-block">
              <div class="v-uikit-area-item">
                  <div class="wrapper-notifications default">
                      <p>По указанным параметрам в АС Фонда не найдены Договоры. Проверьте корректность ввода.</p>
                  </div>
              </div>
              <div class="v-uikit-area-item">
                  <div class="wrapper-notifications error">
                      <p>По указанным параметрам в АС Фонда не найдены Договоры. Проверьте корректность ввода. Если параметры поиска введены верно, клиенту необходимо обратиться в ПФР для уточнения текущего страховщика по обязательному пенсионному страхованию или обратиться на горячую линию Фонда для уточнения информации по договору НПО.</p>
                  </div>
              </div>
              <div class="v-uikit-area-item">
                  <div class="wrapper-notifications success">
                      <p>По указанным параметрам в АС Фонда не найдены Договоры. Проверьте корректность ввода. Если параметры поиска введены верно, клиенту необходимо обратиться в ПФР для уточнения текущего страховщика по обязательному пенсионному страхованию или обратиться на горячую линию Фонда для уточнения информации по договору НПО.</p>
                  </div>
              </div>
               <div class="v-uikit-area-item">
                  <div class="wrapper-notifications steps">
                      <div class="wrapper-notifications-icon-step">
                          <svg>
                              <use xlink:href="@/assets/img/sprite.svg#icon-step1"></use>
                          </svg>
                      </div>
                      <p>По указанным параметрам в АС Фонда не найдены Договоры. Проверьте корректность ввода. Если параметры поиска введены верно, клиенту необходимо обратиться в ПФР для уточнения текущего страховщика по обязательному пенсионному страхованию или обратиться на горячую линию Фонда для уточнения информации по договору НПО.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'v-uikit',
    computed: {
        getTable1() {
            return this.$store.getters.getTable1
        }
    }
}
</script>

<style lang="scss" scoped>
.v-uikit {
    padding: 20rem;
    &-title {
        font-size: 20rem;
        line-height: 34rem;
        color: #506586;
        font-weight: 700;
        margin-bottom: 20rem;
    }
    &-area + .v-uikit-area {
        margin-top: 20rem;
    }
    &-area {
        padding: 40rem 20rem;
        border: 1rem dashed #506586;
        &-item.text, &-item.icon-text {
            min-width: 740rem;
        }
        &-item {
            display: grid;
            grid-gap: 10rem;
            grid-template-columns: repeat(5, 1fr);
            &-wrap {
                display: grid;
                align-items: center;
                grid-gap: 10rem;
            }
        }
        &-block + .v-uikit-area-block {
            margin-top: 50rem;
        }
        &-block {
            display: grid;
            grid-gap: 20rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &-area.buttons {
        .v-uikit-area-block {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &-area.inputs {
        .v-uikit-area-block {
            grid-template-columns: repeat(4, 1fr);
        }
        .v-uikit-area-item {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-area.tables {
        .v-uikit-area-block {
            grid-template-columns: repeat(3, 1fr);
        }
        .v-uikit-area-block.type-2 {
            grid-template-columns: repeat(1, 1fr);
        }
        .v-uikit-area-item {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-area.check {
        .v-uikit-area-item {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-area.notifications {
        .v-uikit-area-block {
            grid-template-columns: repeat(1, 1fr);
        }
        .v-uikit-area-item {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

// .v-uikit-area.buttons, .v-uikit-area.inputs, .v-uikit-area.tables {
//     display: none;
// }
</style>