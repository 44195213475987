<template>
    <div class="v-home grid-dashboard grid-column-2">
        <div class="v-home-info grid-item">
            <transition name="fade" appear>
                <div class="info-block area">
                    <div class="info-block-content">
                        <p>Необходимо распечатать форму соглашения об ЭДО на обработку персональных данных и передать его клиенту на подпись</p>
                        <div class="wrapper-tooltip">
                            <div class="tooltipe" name="tooltipe-1">
                                <tippy
                                    to="tooltipe-1"
                                    followCursor="false"
                                    placement='right-center' 
                                    theme='light v-home-tooltipe'
                                >
                                    <div>После подписи клиента не забудьте отметить check-box «Соглашение об ЭДО на обработку персональных данных получено» для поиска клиента в базе фонда
                                    </div>

                                </tippy>
                                <div class="tooltipe-icon">
                                    <svg>
                                        <use xlink:href="../../assets/img/sprite.svg#tooltipe-icon-small-dark"></use>
                                    </svg>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <button class="btn-middle btn-text primary">Распечатать согласие</button>
                </div>
            </transition>            
        </div>
        <div class="v-home-search grid-item">
            <div class="v-home-search-top main-title-wrap">
                <div class="v-home-search-title main-title">
                    <svg>
                        <use xlink:href="../../assets/img/sprite.svg#search-icon"></use>
                    </svg>
                    <p class="h2 type-1">Поиск клиента</p>
                </div>
                <div class="v-home-search-title-check">
                    <div class="wrapper-checkbox inline">
                      <label class="checkbox">
                          <input type="checkbox" v-model="checkConsent">
                          <div></div>
                          <p>Соглашение об ЭДО на обработку персональных данных получено</p>
                      </label>
                  </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="v-home-search-main">

                    <FormulateForm class="v-home-form module-zayavlenie"
                                    ref="homeForm"
                                    name="homeForm"
                                    @validation="errorsSyntax({}, 'homeForm')"
                                    #default="{ hasErrors }"
                                    aria-autocomplete="off"
                    >

                        <div class="area">
                            <FormulateErrors /> 

                            <p class="v-home-form-area__title h3">Поиск клиента по СНИЛС/номеру телефона/номеру договора</p>

                            <div
                                class="v-home-form-notifications"
                                v-show="isElVisible"
                            >
                                    <div class="wrapper-notifications default">
                                    <p>Для поиска клиента по «ФИО» необходимо заполнить поля «Дата рождения» и «Серия и номер паспорта»</p>
                                </div>
                            </div>
                            <div class="v-home-form-wrap grid-dashboard-form grid-column-3">
                                <FormulateInput
                                    type="text"
                                    name="snils"
                                    :validation="localShemes.snils"
                                    maxlength="14"
                                    v-mask="'###-###-### ##'"
                                    v-model="dataSearch.snils"
                                    label="СНИЛС"
                                    class="big"
                                    v-changeFocusBlur
                                    error-behavior="submit"
                                />
                                <!-- autocomplete="off" -->
                                <FormulateInput
                                    type="text"
                                    name="mobile_phone"
                                    :validation="localShemes.phone"
                                    v-mask="'+7 ### ### ####'"
                                    v-model="dataSearch.mobile_phone"
                                    maxlength="15"
                                    label="Номер телефона"
                                    class="big"
                                    v-changeFocusBlur
                                    error-behavior="submit"
                                    autocomplete="off"
                                />


                                <FormulateInput
                                    type="text"
                                    ref="num_doc"
                                    name="num_doc"
                                    label="Номер договора"
                                    class="big"
                                    maxLength="14"
                                    @input="changeOptionalInputs"
                                    :validation="localShemes.numDoc"
                                    v-changeFocusBlur
                                    v-model="dataSearch.num_doc"
                                    error-behavior="submit"
                                    autocomplete="off"
                                />

                                <FormulateInput
                                    type="text"
                                    ref="dul_fio"
                                    name="dul_fio"
                                    @input="changeOptionalInputs"
                                    :validation="localShemes.fio"
                                    maxlength="302"
                                    v-mask="$masks.upperCyrilic"
                                    v-model="dataSearch.dul_fio"
                                    label="ФИО"
                                    class="big together-input"
                                    v-changeFocusBlur
                                    v-show="isElVisible"
                                    error-behavior="submit"
                                    autocomplete="off"
                                />


                                <Celendar type="one" 
                                        v-show="isElVisible"
                                        :dates="dataSearch"
                                        ref="birth_date"
                                        prop="birth_date"
                                        name="birth_date"
                                        label="Дата рождения"
                                        :parentFunc="changeOptionalInputs"
                                        :validation="localShemes.birthDate"
                                        classes="big together-input"
                                />



                                <FormulateInput
                                    type="text"
                                    ref="passportSeriesNumber"
                                    name="passportSeriesNumber"
                                    @input="changeOptionalInputs"
                                    :validation="localShemes.passNumSeries"
                                    maxlength="11"
                                    v-mask="'#### ######'"
                                    v-model="dataSearch.passport_seriesnumber"
                                    label="Серия и номер паспорта"
                                    class="big together-input"
                                    v-changeFocusBlur
                                    v-show="isElVisible"
                                    error-behavior="submit"
                                    autocomplete="off"
                                />
                                <FormulateInput
                                    type="text"
                                    name="inn"
                                    :validation="localShemes.inn"
                                    v-model="dataSearch.inn"
                                    v-mask="'############'"
                                    maxlength="12"
                                    label="ИНН"
                                    class="big"
                                    v-changeFocusBlur
                                    v-show="isElVisible"
                                    error-behavior="submit"
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="area-buttons btns-end">
                            <div class="area-buttons-block">
                                <button
                                    @click.prevent="toggleElements"
                                    class="btn-middle btn-text secondary"
                                >
                                    Расширенный поиск
                                </button>

                                <button class="btn-middle
                                                primary
                                                btn-text"
                                        :disabled="!Boolean(unBlockValues) || !checkConsent || hasErrors"
                                        @click="searchClient"
                                    >
                                    Найти
                                </button>

                            </div>
                        </div>
                    </FormulateForm>
                </div>
            </transition>            
        </div>

        <transition name="fade" appear>        
            <Tables v-show="transition"
                    name="История коммуникаций по договорам"
                    group="contracts"
                    typeForm="short"
                    :mainData="contractData"
                    :payload="contractPayload"
                    tableStyle="short_agent_contract"
                    listFullPath="history/contract"
            />
        </transition>


        <!-- <vTemplateForm :role="role" />  -->
        <!-- <button @click="$store.commit('excel/getExcelList')">Выгрузка в excel</button> -->

    </div>
</template>

<script>

import { mapState } from 'vuex'
import vTemplateForm from './v-template-form'
import Tables from '@/components/local/tables/index'
import Celendar from '@/components/local/celendar'

export default {
    name: 'v-home',

    computed: {

        ...mapState({


            contractData:       state => state.getStatementContracts.contractData,
            contractError:      state => state.getStatementContracts.contractError,
            contractPayload:    state => state.getStatementContracts.contractPayload,

            findClientError:        state => state.findClient.error,
            findClientData_errors:  state => state.findClient.data_errors,
            findClientPath:         state => state.findClient.path
        }),

        unBlockValues() {
            return Object.values(this.dataSearch).filter(prop => prop).length
        }
    },

    watch: {
      'dateForDatePicker.birth_date': function () {
        this.dataSearch.birth_date = this.dateForDatePicker.birth_date.toLocaleDateString()
      }
    },

    data(){

        const localShemes = this.$lodash.cloneDeep(this.$schemes)


        return {

            transition: false,

            dateForDatePicker : {
              birth_date: ''
            },
            checkConsent: false,
            isElVisible: false,
            role: 'agent',

            dataSearch: {
                mobile_phone: null,
                snils: null,
                dul_fio: null,
                num_doc: null,
                inn: null,
                birth_date: null,
                passport_seriesnumber: null,
            },

            localShemes,

            lastBunch: 'first',

            // dataSchemes: {
            //     homeForm: ['fio', 'birthDate', 'passNumSeries'],
            // },
            dataSchemes: {
                first: ['fio', 'birthDate', 'passNumSeries'],
                two: ['fio', 'numDoc'],
                three: ['passNumSeries', 'numDoc'],
                twoThree: ['fio', 'numDoc', 'passNumSeries']
            },

        }
    },

    methods: {

        toggleElements(e) {

            this.isElVisible = !this.isElVisible;

            if(e.target.innerText == 'Расширенный поиск') {
                e.target.innerText = 'Упрощенный поиск';
            } else {
                e.target.innerText = 'Расширенный поиск';
            }
        },


        errorsAPI({ error, data_errors }, nameForm){

            this.errorsSyntax({
                    serverError: [error],
                    fieldErrors: data_errors,
                    api: true
                }, nameForm);
        },

        errorsSyntax({ serverError = [], fieldErrors = {}, api = false }, nameForm){

            this.$formulate.handle({

                api: api,

                aggregate: fieldErrors,
                formErrors: serverError,

            }, nameForm)

        },

        async searchClient(){

            //непонятно в каком свойстве передавать номер договора и телефон

            await this.$store.dispatch('findClient/search', {
                params: this.dataSearch
            })


            if(!this.findClientError && !this.findClientData_errors){
                
                this.$router.push(this.findClientPath)

            }


            this.errorsAPI({
                error: this.findClientError,
                data_errors: this.findClientData_errors

            }, 'homeForm')
             

        },

        async changeOptionalInputs(string){


            const { dul_fio, birth_date, passportSeriesNumber, num_doc }  = { ...this.$refs, birth_date: this.$refs?.birth_date?.$refs.celendar }

            let newSchemes = this.$lodash.cloneDeep(this.$schemes),
                 currBunch = null


            const getValue = function(data){

                let value = null

                for(let prop of data){

                    if(prop?.hasValue === true){
                        
                        value = true
                        break;

                    }else{
                        value = false
                    }
                }

                return value

            }

            const vars = {

                first: {

                    bunch: [
                            dul_fio, 
                            birth_date, 
                            passportSeriesNumber 
                        ],
                    
                    schemes: { 
                            ['fio']:           newSchemes.fio,
                            ['birthDate']:     newSchemes.birthDate,
                            ['passNumSeries']: newSchemes.passNumSeries
                        },
                    },

                two: {

                    bunch: [
                            dul_fio, 
                            num_doc 
                        ],

                    schemes: {
                            ['fio']:    newSchemes.fio,
                            ['numDoc']: newSchemes.numDoc,
                        },
                    },

                three: {

                    bunch: [
                            passportSeriesNumber,
                            num_doc 
                        ],

                    schemes: {
                        ['passNumSeries']: newSchemes.passNumSeries,
                        ['numDoc']:        newSchemes.numDoc,
                    }
                },

                twoThree: {

                    bunch: [
                            passportSeriesNumber,
                            num_doc,
                            dul_fio
                        ],

                    schemes: {
                        ['fio']:           newSchemes.fio,
                        ['passNumSeries']: newSchemes.passNumSeries,
                        ['numDoc']:        newSchemes.numDoc,
                    }
                }

            }

            const hasValues = {
                first: getValue(vars.first.bunch),
                two:   getValue(vars.two.bunch),
                three: getValue(vars.three.bunch),
                twoThree: getValue(vars.twoThree.bunch)
            }


            if(hasValues.first && !num_doc.hasValue){

                this.lastBunch = currBunch = 'first'

            }

            // else if(hasValues.first && num_doc.hasValue){

            //     this.lastBunch = currBunch = 'two'

            // }

            else if(hasValues.twoThree && !passportSeriesNumber.hasValue && !dul_fio.hasValue){

                this.lastBunch = currBunch = 'twoThree'

            }
            else if(hasValues.two && !passportSeriesNumber.hasValue){

                this.lastBunch = currBunch = 'two'

            }else if(hasValues.three && !dul_fio.hasValue){

                this.lastBunch = currBunch = 'three'

            }
        


            if(currBunch){

                await this.$deactivateScheme.more.call(this, {
                        activateForm: currBunch,
                        getHasErrors: currBunch
                    })
                        
            }else{

                Object.assign(this.localShemes, vars[this.lastBunch]?.schemes)

            }
 
            
            if(string){

                const event = new Event('submit', { cancelable: true }),
                       form = this.$refs.homeForm.$el

                form.dispatchEvent(event);

            }

        }



    },


    // created(){

    //     //в зависимости от роли, отрисовываем компоненту
    //     const res = this.$cookie.get('role')

    //     if(res){
    //         this.role = res
    //     }else{
    //         this.$router.push({name: 'auth'})
    //     }

    // },


    async mounted(){

        // setTimeout(() => {

        
    //     this.$store.dispatch('modalForErrorIdent/open', {
    //         title: 'Вы уверены, что хотите прервать процесс оформления заявления?',
    //         text: 'Заполненные данные сохранятся на странице «Черновики». Вы сможете продолжить заполнения анкеты в любое время'
    //     })

    // }, 1000)

        await this.$store.dispatch('getStatementContracts/get', {
                        init: 'contract',
                        type: 'short'
                    })
                    
                this.transition = true
    },

    components: {
        vTemplateForm,
        Tables,
        Celendar
    }
}
</script>

