const rusCryteries = {
    dateOper:   'Дата операции',
    dateWalk:   'Дата обращения',
    dateStatus: 'Дата статуса',
    agent:      'Агент',
    thing:      'Предмет обращения',
    status:     'Статус обращения',
    fio:        'ФИО клиента',
    product:    'Продукт',
    passport:   'Серия и номер паспорта',
    snils:      'СНИЛС',
    inn:        'ИНН',
    numContract:'Номер договора',
    mobile:     'Номер телефона',
    type:       'Предмет обращения'
}

const filterCryteries = {

    statement: {
        dateOper:   'created_at',
        // dateWalk:   'Дата обращения',
        // dateStatus: 'Дата статуса',
        agent:      'agent_ul_name',
        // thing:      'Предмет обращения',
        status:     'status_for_client',
        fio:        'first_name',
        // product:    'Продукт',
        // passport:   'Серия и номер паспорта',
        // snils:      'СНИЛС',
        // inn:        'ИНН',
        numContract: 'declaration_number',
        // mobile:     'Номер телефона',
        thing:        'type',
        up:           'True',
        down:         'False'
    },

    contract: {
        dateOper:   'create_date',
        // dateWalk:   'Дата обращения',
        dateStatus: 'stage_date',
        // agent:      'Агент',
        // thing:      'Предмет обращения',
        // status:     'Статус обращения',
        fio:        'first_name',
        // product:    'Продукт',
        // passport:   'Серия и номер паспорта',
        // snils:      'СНИЛС',
        // inn:        'ИНН',
        // numContract:'Номер договора',
        // mobile:     'Номер телефона',
        // type:       'Предмет обращения',
        up:           'True',
        down:         'False'
    }

}

export { rusCryteries, filterCryteries }