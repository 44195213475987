export default function getCurrentDateInline(){

    // создает и возвращает сегодняшнюю дату в реверс формате с дефисами
    let curr  = new Date(Date.now()),
        day   = curr.getDate(),
        month = curr.getMonth() + 1,
        year  = curr.getFullYear()

    if(day < 10) day = 0 + day.toString()
    if(month < 10) month = 0 + month.toString()

    return `${year}-${month}-${day}`

}