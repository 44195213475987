<template>
  <div class="v-history grid-dashboard">
    
    <div class="v-history-table grid-item">

      <Tables
        :name="name"
        group="drafts"
        typeForm="extended"
        :mainData="data"
        tableStyle="full_drafts_contract"
        :downloadMore="downloadMore"
        :inputsView="['operation']"
        :payload="payload"
        :TotalAmount="TotalAmount"
        :rowsPaginator="rowsPaginator"
        :propFuncs="{ deleteDraft }"
      />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tables from '@/components/local/tables/index'

export default {

  name: 'v-drafts',

  data(){

    return {
      contracts: [],
      currPath: null,
      data: null,
      name: '',
      group: '',
      tableStyle: '',
      payload: false,
      TotalAmount: 0,

    }

  },

  watch: {

    "draftPayload"(){

      this.payload = this[this.group.slice(0, -1) + 'Payload']

    },

    "draftData": function(){

      this.data = this.draftData
      this.group = 'drafts'
      this.name = 'Черновики договоров'
      this.TotalAmount = this.draftTotalAmount
    },


  },

  computed: {
      
    ...mapState({

        draftData:       state => state.getStatementContracts.contractData,
        draftError:      state => state.getStatementContracts.contractError,
        draftPayload:    state => state.getStatementContracts.contractPayload,
        draftTotalAmount:   state => state.getStatementContracts.contractTotalAmount,

        deletedDraft:     state => state.deleteIpoContract.deleted,

        rowsPaginator:       state => state.getStatementContracts.rowsPaginator,
    }),

  },

  async mounted(){
 
    await this.getDataWithPath()

  },


  methods: {
    
    async deleteDraft(id){

      let index = null

      // не включать просто так. Договоров и так мало((
      await this.$store.dispatch('deleteIpoContract/del', { id })

      if(this.deletedDraft){

        index     = this.data.findIndex(item => item.id === id)
        this.data = this.data.splice(index, 1)

      }

    },

    async downloadMore({ limit, startType, sort, btnClick }){

      await this.$store.dispatch('getStatementContracts/get', {
                      init: this.currPath,
                      type: 'extended',
                      btnClick,
                      startType,
                      limit,
                      sort,
                      isDraftsClient: true,
                      params: {
                        stage: '0, 1'
                      }
                  })

    },

    async getDataWithPath(){

        const path = this.$route.path

        // для action используется название contract
        if(path.includes('drafts')){

            this.currPath = 'contract'

        }else{

            //404

        }

      await this.downloadMore({})

    }


  },

  components: {
    Tables
  }

}
</script>

<style>

</style>