import axios from 'axios'
import { getFields, getIdents, getSystem } from './common_errors'
import isEmptyObj from '@/assets/functions/check_object_is_empty'

const host = 'apitest.npff.ru',
  nameHost = 'АО «Эволюция»'

  axios.defaults.withCredentials = true

const instance = {


    //если статус код другой, то в консоль выбрасывается стек ошибки
    validateStatus(status) {
        return status >= 200 && status < 300
    },

    transformResponse(data) {

        let objData = {
            ...JSON.parse(data),
            nameHost: nameHost,
          },
        errorData = null

        if(objData.result === 'error'){

            errorData = {
                field_errors:          getFields(objData), //object
                identification_error:  getIdents(objData), //string
                system_error:          getSystem(objData), //string
                // nameHost: nameHost,
            }

            const { field_errors, identification_error, system_error } = errorData

            if(!isEmptyObj(field_errors) || identification_error || system_error){
                return errorData
            }

        }



        return objData


    }
}


export const axJson = axios.create({
    
    baseURL: `https://api.evonpf.ru/site_contract_api_v4t.php`,
    headers: {
        "Content-type": "application/json",
        'Credentials': 'include'
    },
    withCredentials: true,
    ...instance
});
