<template>

  <div class="suggestion-block">


    <FormulateInput
        ref="workPlace"
        name="workPlace"
        type="text"
        v-model="text"
        maxLength="100"
        label="Место работы"
        class="big"
        @focus="() => visibleList = true"
        @blur="noActiveList"
        error-behavior="submit"
        v-changeFocusBlur
    />

    <transition name="fade" appear>

      <div v-if="data.length && visibleList" class="result-items">

        <div v-for="(item, key) of data" 
            :key="key" @click="selected(item.name)" 
            class="one-item" 
            name="one-item"
        >

          {{item.name}}

        </div>
      </div>

    </transition>

  </div>

</template>

<script>


export default {

  name: 'TestArea',

  watch: {

    "text"(){

      this.data = this.full_list.filter((item) => {

        return item.name.toLowerCase().includes(this.text.toLowerCase())
                      
      })
    
    }

  },

  data(){

    const full_list = [
        { id: 1, name: 'Golden Retriever' },
        { id: 2, name: 'Cat' },
        { id: 3, name: 'Squirrel' },
        { id: 4, name: 'Cat' },
        { id: 5, name: 'Crot' },
        { id: 6, name: 'Call' },
        { id: 7, name: 'Cool' },
        { id: 8, name: 'Bloom' },
        { id: 9, name: 'Brood' },
        { id: 10, name: 'Left' },
      ]

    return {

      text: '',

      data: [],

      full_list,

      visibleList: false,
      selecting: false,

    }

  },


  methods: {
    
    selected(item) {

      this.text = item;
      this.selecting = true

    },

    noActiveList(e){

      if(this.selecting || e.target.name !== 'one-item'){

        this.visibleList = false

        this.$nextTick(() => {
          this.selecting = false
        })

      }

    }

  },


}
</script>

<style scoped>

  .suggestion-block{

    position: relative;
    width: 200px;
    margin: 0 auto;

  }
  .result-items{
    position: absolute;
    display: block;
    background: white;
  }

  .test{
    background-color: red;
    width: 100%;
    height: 400px;
  }

  .one-item{
    height: 50px;
  }

</style>