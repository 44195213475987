<template>
</template>

<script>

export default {
    name: 'f-logger',
    props: {
        refs: Object,
        route: Object
    },
    //логирование смены роутов
    watch: {
        "$route.name"(){

            const action = {
                moveUrl: this.route.name,
                move: 'change_route'
            }

            this.sendLoging(action)
            
        }
    },

    created(){

        if(this.route.name){
            
            const action = {
                moveUrl: this.route.name,
                move: 'change_route'
            }
            this.sendLoging(action)
        }

    },

    //накидываем слушатели
    mounted(){


        const main = this.refs.main

        //логируем клики
        main.addEventListener('click', this.eventer)

        //логируем смену у input date
        main.addEventListener('change', this.eventer)

        //логируем нажатие клавиш
        main.addEventListener('keydown', this.eventer)

    },
    //отчищаем слушатели
    beforeDestroy(){

        const main = this.refs.main

        main.removeEventListener('click', this.eventer)
        main.removeEventListener('change', this.eventer)
        main.removeEventListener('keydown', this.eventer)

    },
    methods: {

        eventer(e){

            if(!e.isTrusted) return;

            const trg = e.target,
                 type = trg?.type

            const data = {
                tage:     trg.tagName.toLowerCase(),
                type:     type,
                idBlock:  trg?.id,
                value:    (type === 'checkbox') ? trg.checked : trg.value,
                time:     Date.now(),
                pressKey: e.key,
                move:     e.type,
                cursor: {
                    x: e.clientX,
                    y: e.clientY
                }
            }

            this.sendLoging(data)

        },

        sendLoging(data){

            if(data.type === 'checkbox' && data.move === 'click') return;

            if(
                ~['button', 'input'].indexOf(data.tage)
                ||
                'change_route' === data.move
            ){

                // this.$store.dispatch('sendLoging/sendLoging', {
                //                         params: data
                //                     })

                // console.log(data)
            }
        }
    },

}
</script>