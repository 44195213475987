import styleSizes from '@/styles/setup/size.scss';
import store from '@/store';

class BreakpointManager {
    constructor() {
        this.breakpoints = null;
        this.currentSize = 'md';
        this.currentRatio = null;
        this.currentFontSize = null;
        this.updateRequired = true;
        this.parseBreakPoints();
        this.handleAnimationFrame();
        window.onresize = () => this.handleResize();
    }

    handleResize() {
        this.updateRequired = true;
    }

    handleAnimationFrame() {
        if (this.updateRequired) {
            this.updateRequired = false;
            this.calculateCurrentFontSize();
            store.commit('screen/SET_CURRENT_SIZE', this.currentSize);
            store.commit('screen/SET_CURRENT_RATIO', this.currentRatio);
            store.commit('screen/SET_CURRENT_FONT_SIZE', this.currentFontSize);
        }
        window.requestAnimationFrame(() => this.handleAnimationFrame());
    }

    calculateCurrentFontSize() {
        const innerWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
        const { ethalonWidth } = this.breakpoints[this.currentSize];
        if(innerWidth > 1024 && innerWidth < 1441) {
            this.currentFontSize = innerWidth / ethalonWidth;
            document.children[0].style.fontSize = `${this.currentFontSize}px`;            
        } else {
            document.children[0].style.fontSize = 0.7111111 + 'px'
        }
    }

    parseBreakPoints() {
        this.breakpoints = Object.keys(styleSizes)
            .reduce((result, key) => {
                const parts = key.split('-');
                const prefix = parts.shift();
                const property = parts.join('-');
                if (!Object.prototype.hasOwnProperty.call(result, prefix)) {
                    result[prefix] = {};
                }
                result[prefix][property] = styleSizes[key];
                return result;
            }, {});
    }
}

export default new BreakpointManager();
