<template>

    <div class="v-popup">
        <div class="v-popup-block">
            <div class="v-popup-main">
                <p class="v-popup-title">{{title}}</p>
                <p class="v-popup-desc">{{text}}</p>
            </div>
            <div class="v-popup-btns">
                <button 
                    class="btn-big btn-text primary"
                >
                    Прервать
                </button>
                <button 
                    class="btn-big btn-text secondary"
                    @click="$emit('close')"
                >
                    Отмена
                </button>
            </div>
        </div>
        
    </div>  

</template>

<script>

export default {
  
  name: 'v-popup',

  props: {
    title: String,
    text: String,
  },

}
</script>