<template>
    <div class="v-auth">
        <div class="v-auth-wrap area">
             <a href="/" class="v-auth-logo">
                <img src="@/assets/img/svg/logo-big.svg" alt="">
            </a>
            <transition name="fade" appear>
                <div 
                    v-if="auth"
                    class="v-auth-block"
                >
                    <p class="v-auth-title">Вход в систему</p>

                    <!-- эволюция
                        test
                        test_afo_pass1
                    -->

                    <FormulateForm ref="authForm" class="v-auth-form" name="authForm" >

                            <FormulateErrors />
                            
                            <div class="v-auth-tabs">
                                <ul class="grid-column-3">
                                    <li
                                        v-for="tab in tabs"
                                        :key="tab.id"
                                        :class="tab.class"
                                        @click="setActive"
                                        :data-type="tab.labelInput"
                                    >
                                        {{ tab.name }}
                                    </li>
                                </ul>
                            </div>

                            <FormulateInput
                                name="login"
                                type="text"
                                v-model="send.login"
                                v-mask="mask"
                                :maxLength="maxLength"
                                :validation="validation"
                                :label="label"
                                class="big"
                                v-changeFocusBlur
                                error-behavior="submit"
                                autocomplete="off"
                            />
                            <FormulateInput
                                name="password"
                                :type="toogleType"
                                label="Пароль"
                                v-model="send.password"
                                :validation="$schemes.password"
                                class="big"
                                v-changeFocusBlur
                                error-behavior="submit"
                                :watchView="toogleType"
                                autocomplete="off"
                            />
                            
                            <button class="btn-big btn-text primary" @click="errorsAPI">
                                Войти
                            </button>

                    </FormulateForm>

                    <p class="v-auth-subtext">  
                        Не можете войти?
                        <router-link 
                            to="/recovery/sending-link"
                            class="btn-big btn-text link-inline"
                            tag="button"
                        >
                            Восстановите пароль
                        </router-link>
                    </p>

                </div>
            </transition>
            <transition name="fade" appear>
                <div
                    v-if="authLock"
                    class="v-auth-lock"
                >
                    <div class="wrapper-notifications error">
                        <p>Доступ к системе временно ограничен</p>
                    </div>
                    <div class="v-auth-lock-icon">
                    <img src="@/assets/img/svg/auth-lock-icon.svg" alt="">
                    </div>
                    <div class="v-auth-lock-text">
                        <p>Ваша учетная запись временно заблокирована.</p>
                        <p>Вам необходимо подождать 15 минут для продолжения работы</p>
                    </div>
                </div>
            </transition>
            <transition name="fade" appear>
                <div 
                    v-if="passportRecovery"
                    class="v-auth-passport-recovery"
                >
                    <p class="v-auth-passport-recovery-title">Восстановление пароля</p>
                    <FormulateForm
                        class="v-auth-passport-recovery-form grid-dashboard"
                    >
                        <FormulateErrors />

                        <div class="v-auth-passport-recovery-form-block grid-dashboard-form">
                            <div class="grid-item">
                                <FormulateInput
                                    name="email"
                                    type="text"
                                    label="E-mail"
                                    class="big"
                                    v-changeFocusBlur
                                />
                            </div>
                            <div class="v-auth-passport-recovery-recapcha grid-item grid-dashboard grid-column-2">
                                <div class="grid-item">
                                    Картинка-код
                                </div>
                                <div class="grid-item">
                                    <FormulateInput
                                        name="email"
                                        type="text"
                                        label="Защитный код"
                                        class="big"
                                        v-changeFocusBlur
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div class="v-auth-passport-recovery-buttons grid-dashboard">
                            <button class="btn-big btn-text primary">
                                Отправить ссылку на восстановление
                            </button>
                            <button class="btn-big btn-text link-inline">
                                Вернуться к авторизации
                            </button>
                        </div>
                    </FormulateForm>

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
// @click="handleSubmit"
import {mapGetters} from 'vuex'


export default {

    name: 'v-auth',

    data() {

        return {

            validation:[
                    ['bail'],
                    ['required'],
                    ['min', 15, 'length'],
                    ['matches', /^\+[0-9 ]+$/],
                ],

            auth: true,
            authLock: false,
            passportRecovery: false,
            label: 'Телефон',
            mask: '+7 ### ### ####',
            maxLength: 15,

            tabs: [
                {
                    id: 1,
                    labelInput: 'login',
                    name: 'Логин',
                    class: ''
                },
                {
                    id: 2,
                    labelInput: 'phone',
                    name: 'Мобильный телефон',
                    class: 'active'
                },
                {
                    id: 3,
                    labelInput: 'email',
                    name: 'Почта',
                    class: ''
                }
            ],

            toogleType: 'password',

            errors: {
                login: {},
                password: {}
            },

            send: {
                login: '',
                password: '',
                token: ''
            }
        }
    },

    computed: mapGetters('auth', ['currState']),


    methods: {

        setActive(e) {

            const tabsAll = document.querySelectorAll('.v-auth-tabs li'),
                     type = e.target.getAttribute('data-type')

            if(!e.target.classList.contains('active')) {

                tabsAll.forEach((tab) => {
                    tab.classList.remove('active');
                });

                e.target.classList.add('active')

                this.send.login = ''

                switch(type){

                    case 'email':
                        this.label = 'Почта'
                        this.mask = ''
                        this.maxLength = 20
                        this.validation =  [
                                    ['bail'],
                                    ['required', 'trim'],
                                    ['matches', /^[a-zA-Z0-9-._]+@[a-z]+\.[a-zA-Z]+$/],
                                    ['min', 5, 'length']
                                ]
                        break

                    case 'phone':

                        this.label = 'Телефон'
                        this.mask = '+7 ### ### ####'
                        this.maxLength = 15
                        this.validation = [
                                ['bail'],
                                ['required'],
                                ['min', 15, 'length'],
                                ['matches', /^\+[0-9 ]+$/],
                            ]
                        break

                    case 'login':
                        this.label = 'Логин'
                        this.mask = ''
                        this.maxLength = 20
                        this.validation = [
                                ['bail'],
                                ['required', 'trim'],
                                ['min', 3, 'length'],
                                ['matches', /^[a-zA-Z-_]+$/],
                            ]
                        break
                }


            }
        },

        async errorsAPI(){


            await this.$store.dispatch('auth/auth', {
                                        params: this.send
                                    })

            const hasErrors = this.$formulate.registry.get('authForm').hasErrors
            const serverError = this.currState.error

            this.errorsSyntax({
                serverError: [serverError],
                api: true
            })


            if(this.currState.path && !hasErrors){

                this.$router.push({name: 'home'})

            }

        },

        errorsSyntax({ serverError = [], api = false }) {


            const result = this.$formulate.handle({

                formErrors: serverError,
                errors: this.errors,
                api: api,

            }, 'authForm')

            return result
        },


    },

}

</script>
