<template>

    <FormulateForm @submit.prevent="handleSubmit">

        <div id="switch-document" @click="changeDocument">
            <a href="#contract" :class="active.contract" name="contract">Договор (ИИП)</a>
            <a href="#statement" :class="active.statement" name="statement">Заявление (ППО)</a>
        </div>

        <div class="wrapper-input">
            <FormulateInput
                name="statusDoc"
                v-model="statusDoc"
                type="select"
                placeholder="Статус документа"
                :options="status"
                required
            />
        </div>


        <div class="oformlenie">

            <span>Дата оформления</span>

            <div class="wrapper-input">
                <FormulateInput
                    id="datefrom"
                    type="text"
                    name="datefrom"
                    max="2040-01-01"
                    v-mask="'##.##.####'" 
                    v-model="send.datefrom"
                    :validation="$schemes.date"
                    placeholder="дата с"
                    autocomplete="off"
                    required
                />
                <span class="help-block"></span>
            </div>


            <div class="wrapper-input">
                <FormulateInput
                    id="dateto"
                    type="text"
                    name="dateto"
                    max="2040-01-01"
                    v-mask="'##.##.####'" 
                    v-model="send.dateto"
                    :validation="$schemes.date"
                    placeholder="дата до"
                    autocomplete="off"
                    required
                />
                <span class="help-block"></span>
            </div>

        </div>

        <div class="date-status">

            <span>Дата статуса</span>

            <div class="wrapper-input">
                <FormulateInput
                    id="stage_datefrom"
                    type="text"
                    name="stage_datefrom"
                    max="2040-01-01"
                    v-mask="'##.##.####'" 
                    v-model="send.stage_datefrom"
                    :validation="$schemes.date"
                    placeholder="дата с"
                    autocomplete="off"
                    required
                />
                <span class="help-block"></span>
            </div>

            <div class="wrapper-input">
                <FormulateInput
                    id="stage_dateto"
                    type="text"
                    name="stage_dateto"
                    max="2040-01-01"
                    v-mask="'##.##.####'" 
                    v-model="send.stage_dateto"
                    :validation="$schemes.date"
                    placeholder="дата до"
                    autocomplete="off"
                    required
                />
                <span class="help-block"></span>
            </div>


        </div>

        <div class="right-buttons">

            <button>Найти</button>
            <button>Сбросить</button>

            <button 
                v-if="role !== 'agent'"
            >Excel</button>

        </div>


        <div class="wrapper-input">
            <FormulateInput
                v-if="role !== 'agent'"
                v-model="send.agent_fio"
                type="text" 
                placeholder="ФИО агента"
            />
            <span class="help-block"></span>
        </div>

        <div class="wrapper-input">
            <FormulateInput
                v-model="send.combined"
                type="text" 
                placeholder="ФИО, СНИЛС или телефон клиента"
            />
            <span class="help-block"></span>
        </div>

        <div class="wrapper-input">
            <FormulateInput
                v-if="role === 'responsible'"
                v-model="send.agent_ul_name"
                type="text" 
                placeholder="Наименование Агента - ЮЛ"
            />
            <span class="help-block"></span>
        </div>

        <table>
            <thead>
                <tr>
                    <td v-if="role === 'responsible'">
                        № п/п
                    </td>

                    <td>Дата оформления</td>
                    <td>ФИО клиента</td>
                    <td>Тип документа</td>

                    <td v-if="role === 'responsible'">
                        Номер документа
                    </td>

                    <td>Форма документа</td>

                    <td v-if="role !== 'agent'">
                        Сумма взноса
                    </td>

                    <td v-if="role === 'responsible'">
                        ФИО агента
                    </td>

                    <td v-if="role === 'responsible'">
                        Наименование агента - ЮЛ
                    </td>

                    <td v-if="role !== 'agent'">
                        ФИО агента/работника
                    </td>

                    <td>Статус</td>
                    <td>Дата статуса</td>

                </tr>
            </thead>
        </table>

        
    </FormulateForm>

</template>


<script>

//по нажатию на любой договор/заявление обязательно записать айди в кукки

import {mapGetters} from 'vuex'

export default {
    name: 'v-template-form',
    props: {
        role: String
    },

    data(){

        this.statement = {
            0: 'На этапе оформления' ,
            1: 'На проверке в СМЭВ',
            2: 'Ожидает оплаты',
            3: 'Ожидает зачисления взноса',
            4: 'Оплачен',
            5: 'Отказ',
        }
        
        this.contract = {
            0: 'На этапе оформления',
            1: 'На этапе рассмотрения',
            2: 'Принято фондом',
            3: 'Отказ',
            4: 'Отправлено на доработку',
        }

        return {
            status: null,

            active: {
                contract: null,
                statement: null
            },
            statusDoc: '',

            send: {
                stage: '',
                datefrom: '',
                dateto: '',
                stage_datefrom: '',
                stage_dateto: '',
                agent_fio: '',
                combined: '',
                agent_ul_name: '',

                email: '?',
                first_name: '?',
                middle_name: '?',
                last_name: '?',

                count_from: 'dop_page',
                count_to: 'dop_page'
            }

        }


    },


    computed: mapGetters('home', ['currState']),

    methods: {

        changeDocument(e){

            if(e.target.name === 'contract'){
                
                this.status = this.contract
                this.active.contract = 'active'
                this.active.statement = null

            }else{
                this.status = this.statement
                this.active.contract = null
                this.active.statement = 'active'
            }

        },
        async handleSubmit(){
            console.log(this.send)
            await this.$store.dispatch('home/filter', {
                                    ...this.send
                                })

        

        }
    }

}

</script>

<style lang="css">

/* .active {
    border: 2px solid red;
    display: block;
    width: fit-content;
} */

</style>