<template>
  <div class="grid-dashboard">
    <div class="v-history-top grid-item">
      <div class="v-history-top-title main-title">
        
        <svg v-if="group !== 'drafts'">
          <use xlink:href="../../../assets/img/sprite.svg#clock-icon"></use>
        </svg>

        <svg v-if="group === 'drafts'">
          <use xlink:href="../../../assets/img/sprite.svg#file-text-icon"></use>
        </svg>

        <p class="h1">{{ name }}</p>

      </div>
    </div>
    
    <div v-if="group === 'drafts'">

      <div class="v-history-filters grid-item filters-drafts">
      <!-- <div class="filters grid-dashboard"> -->

        <FormulateForm name="twoForm" class="filters" #default="{ hasErrors }">

          <div class="area grid-inputs">

              <div class="filters-item grid-item filters-title">
                  <p class="h2 type-2">Фильтры</p>
              </div>

              <div class="filters-item grid-item date">

                  <div v-if="~inputsView.indexOf('operation')" class="filters-item-inputs">

                    <div class="filters-item-title">
                        <p class="h3">По дате операции</p>
                    </div>

                    <div class="filters-item-inputs-wrap">

                      <Celendar type="period" 
                                :dates="dates"
                                prop="operation"
                      />

                      <ButtonSearch v-if="group === 'drafts'" 
                                    :hasErrors="hasErrors" 
                                    :search="search"
                      />
<!-- :disabled="dates.operation && dates.operation.length < 10 || !dates.operation" -->
                    </div>

                  </div>

              </div>
          </div>

          <FormulateErrors />

        </FormulateForm>
      </div>


      <div class="area-buttons btns-end filters reset-filters-drafts">
        <div class="area-buttons-block">
            <button class="btn-small btn-text-icon link-inline reset-table">
                <span class="btn-icon">
                    <svg>
                    <use xlink:href="../../../assets/img/sprite.svg#cross-small"></use>
                    </svg>
                </span>

                <span @click.prevent="clearFilter">
                    Сбросить фильтрацию и сортировку
                </span>

            </button>

        </div>
      </div>
      
    </div>

    <div v-if="group !== 'drafts'" class="v-history-filters grid-item">
      <!-- <div class="filters grid-dashboard"> -->

            <FormulateForm name="twoForm" class="filters" #default="{ hasErrors }">

              <div class="area grid-inputs">
                  <div class="filters-item grid-item filters-title">
                      <p class="h2 type-2">Фильтры</p>
                  </div>

                  <div class="filters-item grid-item date">

                      <div v-if="~inputsView.indexOf('operation')" class="filters-item-inputs">

                          <div class="filters-item-title">
                              <p class="h3">По дате операции</p>
                          </div>
                        <div class="filters-item-inputs-wrap">

                          <Celendar type="period" 
                                    :dates="dates"
                                    prop="operation"
                          />

                        </div>

                      </div>


                      <div v-if="~inputsView.indexOf('status')" class="filters-item-inputs">

                          <div class="filters-item-title">
                              <p class="h3">По дате статуса</p>
                          </div>

                          <div class="filters-item-inputs-wrap">

                            <Celendar type="period" 
                                      :dates="dates"
                                      prop="status"
                            />

                          </div>
                      </div>
                  </div>

                  <div v-if="group !== 'drafts'" class="filters-item grid-item switcher">
                      <div class="filters-item-swithers">
                          <div class="filters-item-title">
                              <p class="h3">По статусу</p>
                          </div>
                          
                              <div class="filters-item-swithers-wrap">

                                <!-- <div v-if="group === 'contracts'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.register">
                                    <div></div>
                                    <p>На оформлении</p>
                                    </label>
                                </div> -->

                                <!-- new -->
                                <div v-if="group === 'contracts'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.check">
                                    <div></div>
                                    <p>На проверке</p>
                                    </label>
                                </div>

                                <!-- new -->
                                <div v-if="group === 'contracts'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.waitPay">
                                    <div></div>
                                    <p>Ожидает оплаты</p>
                                    </label>
                                </div>

                                <!-- new -->
                                <div v-if="group === 'contracts'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.enrollment">
                                    <div></div>
                                    <p>Ожидает зачисления</p>
                                    </label>
                                </div>

                                <!-- new -->
                                <div v-if="group === 'contracts'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.paid">
                                    <div></div>
                                    <p>Оплачено</p>
                                    </label>
                                </div>


                                <div v-if="group === 'statements'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.accepted">
                                    <div></div>
                                    <p>Принятые фондом</p>
                                    </label>
                                </div>

                                <div v-if="group === 'statements'" class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.review">
                                    <div></div>
                                    <p>На рассмотрении</p>
                                    </label>
                                </div> 

                                <div class="wrapper-switch">
                                    <label>
                                    <input type="checkbox" v-model="status.adorted">
                                    <div></div>
                                    <p>Отклонено</p>
                                    </label>
                                </div>


                              </div> 
                      </div>
                  </div>
                  
              </div>
              <div class="area-buttons btns-end">
                  <div class="area-buttons-block">
                      <button class="btn-small btn-text-icon link-inline reset-table">
                          <span class="btn-icon">
                              <svg>
                              <use xlink:href="../../../assets/img/sprite.svg#cross-small"></use>
                              </svg>
                          </span>

                          <span @click.prevent="clearFilter">
                              Сбросить фильтрацию и сортировку
                          </span>

                      </button>

                      <ButtonSearch v-if="group !== 'drafts'" 
                                    :hasErrors="hasErrors" 
                                    :search="search"
                      />

                </div>
              </div>
    <FormulateErrors />

          

      </FormulateForm>
    </div>

  </div>
</template>

<script>

import Celendar from '../celendar'
import getCurrentDateInline from '@/assets/functions/get_current_date_inline'
import ButtonSearch from './v-button_search'

export default {

  name: 'v-filters',

  props: {
    name: String,
    group: String,
    typeForm: String,
    setStyles: Function,
    resetSort: Function,
    inputsView: Array
  },


  data(){

    return {

      dates: {
        operation: null,
        status: null
      },

      status: {
        adorted: false,
        register: false,
        check: false,
        waitPay: false,
        enrollment: false,
        paid: false,
        
        accepted: false,
        review: false
      }


    }

  },


  methods: {


    clearFilter(){

        const setDefault = (data, placeholder) => {

          for(let cretery in data){
              data[cretery] = placeholder
          }
        }

        setDefault(this.dates, null)
        setDefault(this.status, false)

    },

    fillCurentDate(){

      let sendDates = {}

      for(let date in this.dates){

        if(date.includes('to') && !this.dates[date]){

          sendDates[date] = getCurrentDateInline()


        }else if(this.dates[date]){

          sendDates[date] = this.dates[date].split('-')
                                            .map(item => item.split('.').reverse().join('.'))
                                            .join('-')

        }

      }
      
      return sendDates
    },

    fillStatuses(){

      let formStatus = [],
          nameProp = null

      for(let data in this.status){


        if(this.status[data] === true){

          if(this.group === 'statements'){

            switch(data){

              case 'accepted':
                formStatus.push('uprid_ok', '1c_done', 'lkk_done')
                break

              case 'review':
                formStatus.push('init')
                break

              case 'adorted':
                formStatus.push('uprid_fail')

            }


          }else if(this.group === 'contracts'){

            switch(data){

              case 'adorted':
                formStatus.push(-1)
                break

              // case 'register':
              //   formStatus.push(0, 1)
              //   break

              case 'check':
                formStatus.push(2)
                break

              case 'waitPay':
                formStatus.push(3)
                break

              case 'enrollment':
                formStatus.push(4)
                break

              case 'paid':
                formStatus.push(5)
                break

            }

          }


        }else if(this.group === 'drafts'){

          formStatus = ['0, 1']
              
        }

      }


      nameProp = (this.group === 'statements') ? 'statuses' : 'stage'

      
      this.$emit('set-statuses', formStatus.join(', '))

      return {
        [nameProp]: formStatus.join(', ')
      }


    },

    async search(){

      const sendDates = this.fillCurentDate(),
           sendStatus = this.fillStatuses()

      //продолжаем делать, после добавления полей и свойств для фильтрации дат
      await this.$store.dispatch('getStatementContracts/get', {
                init: (this.group === 'drafts') ? 'contract' : this.group.slice(0, -1),
                type: this.typeForm,
                params: {
                    ...sendDates,
                    ...sendStatus
                },
                startType: 'filter',
                isDraftsClient: (this.group === 'drafts') ? true : false

      })


      this.setStyles()

    }
  },

  components: {
    Celendar,
    ButtonSearch
  }

}
</script>

<style scoped>

  /* индивидуально для чрновиков. Наверно следет переместить в scss файлы */
  .filters-drafts {
    width: fit-content;
  }

  .reset-filters-drafts{
    margin-bottom: 16rem;
  }

  .filters-drafts .btn-text{
    margin-left: 24px;
  }

</style>
