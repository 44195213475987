import isEmptyObj from '@/assets/functions/check_object_is_empty'

export default function aggregateErrors({ commit },{ data, localError }){

    // const { field_errors, identification_error, system_error } = data

    const { field_errors, identification_error, system_error } = data?.data || data || {}
    let identFormError = ''


    if(identification_error || system_error || localError){

        console.log('Ошибки формы')

        //ошибки идентификации не пойдут на уровне формы
        //теперь выодятся через модальное окно
        if(identification_error){

            this.dispatch('modalForErrorIdent/open', {
                title: 'Критическая ошибка',
                text: identification_error
            },{
                root: true
            })

            identFormError = 'Неудачная идентификация'
        }
        commit('viewError', {
            errMessage: localError || system_error || identFormError
        })

        return false

    }else if(!isEmptyObj(field_errors)){

        console.log('Ошибки полей')

        commit('viewDataError', {
            field_errors: data.field_errors
        })

        return false

    }

    return true;

}
