import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/Auth'
import Home from '../views/Home'
import Client from '../views/Client'
import Drafts from '../views/Drafts'
import ClientList from '../views/ClientList'
import HistoryPage from '../views/History'
import HistoryClientPage from '../views/HistoryClient'

import Contract from '../views/Contract'

import PasswordRecovery from '../views/PasswordRecovery'
import NotFound from '../views/NotFound'
import MainLayout from '../components/layouts/v-main-layout'
import MainLayoutNotFound from '../components/layouts/v-main-layout-not-found'
import MainLayoutService from '../components/layouts/v-main-layout-service'
import UIKit from '../views/UIKit'

//компонент для теста новых модулей
import TestArea from '../views/TestArea'

Vue.use(VueRouter)

const routes = [
    {
        path: '/test_area',
        name: 'TestArea',
        component: TestArea
    },
    {
        path: '/',
        name: 'auth',
        component: Auth
    },

    {
        path: '/recovery',
        name: 'recovery',
        component: PasswordRecovery,
        children: [
            {
                path: 'sending-link',
                name: 'sending-link',
                component: () => import ('../views/PasswordRecovery/sending-link')
            },
            {
                path: 'new-password',
                name: 'new-password',
                component: () => import ('../views/PasswordRecovery/new-password')
            },
        ]
    },


    {
        path: '/uikit',
        name: 'UIKit',
        component: UIKit
    },
    {
        path: '/home',
        name: 'mainLayout',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: Home,
            },
            //черновики. пока не сверстана
            {
                path: '/drafts',
                name: 'drafts',
                component: Drafts,
                children: [
                    {
                        path: 'client/:id',
                        name: 'draftsClient',
                        component: Drafts,
                    },
                ]

            },
            {
                path: '/client/:id',
                name: 'client',
                component: Client,
            },
            {
                path: '/client-list',
                name: 'clientList',
                component: ClientList,
            },

            {
                path: '/history/contract',
                name: 'History',
                component: HistoryPage
            },

            {
                path: '/history-client/:id/contract',
                name: 'HistoryClient',
                component: HistoryClientPage
            },

            {
                path: '/contract_step',
                name: 'contract',
                redirect: {
                    path: '/contract_step/list'
                },
                component: Contract,
                children: [
                    {
                        path: 'list',
                        name: 'contractId-list',
                        component: () => import ('../views/Contract/v-step-list')
                    },
                    {
                        path: '1',
                        name: 'contractId-1',
                        component: () => import ('../views/Contract/v-step-1')
                    },
                    {
                        path: '2',
                        name: 'contractId-2',
                        component: () => import ('../views/Contract/v-step-2')
                    }
                ]
            },

        ],
    },

    {
        path: '/contract_step/final',
        name: 'contractId-final',
        component: MainLayoutNotFound,
        children: [
            {
                path: '/',
                name: 'contractFinal',
                component: () => import ('../views/Contract/v-step-final')
            }
        ]

    },
    {
        path: '/service-work',
        name: 'MainLayoutService',
        component: MainLayoutService,
        children: [
            {
                path: '/',
                name: 'ServiceWork',
                component: () => import ('@/views/Services/v-service-work')
            },
            {
                path: '/service-crash',
                name: 'ServiceCrash',
                component: () => import ('@/views/Services/v-service-crash')
            }
        ]

    },
    {
        path: '/*',
        name: 'MainLayoutNotFound',
        component: MainLayoutNotFound,
        children: [
            {
                path: '/',
                name: '404',
                component: NotFound,
            },
        ]
    },
]

const router = new VueRouter({
  mode: 'history', //abstract hash
  base: process.env.BASE_URL,
  routes
})

// router.replace('/')

export default router
