<template>
    <div>

        <transition name="fade" appear>
            <Filters 
                    v-show="transition.filter"
                    v-if="tableStyle.includes('full')"
                    :name="name"
                    :group="group"
                    :typeForm="typeForm"
                    :setStyles="setStyles"
                    :resetSort="resetSort"
                    :inputsView="inputsView"
                    @set-statuses="stages = $event"
            />
        </transition>

        <div :class="`v-home-${group} grid-item`">

            <div :class="`v-home-${group}-top`">

                <div v-if="tableStyle.includes('short')"
                    :class="`v-home-${group}-title main-title`"
                >
                    <svg>
                        <use xlink:href="../../../assets/img/sprite.svg#clock-icon"></use>
                    </svg>
                    <p class="h2 type-2">{{name}}</p>
                </div>

            </div>


            <transition name="fade" mode="out-in">
                <div v-show="transition.table" v-if="tableScheme.length && renderKey && data.length" :class="`v-home-${group}-main small-table-wrap`">
                    <div class="area small-table">

                        <div :class="searchClientClass">
                            <div class="table">
                                <div class="thead">
                                    <div class="tr">
                                        <div class="tr-wrap">
                                 <!-- :class="`th ${state.cell === Object.keys(item)[0] && tableStyle.includes('full') ? 'active' : ''}`" -->

                                            <div v-for="(item, key) of tableScheme"
                                                :key="key"
                                                :class="`th`"
                                            >
                                                <p :name="key">
                                                    {{ Object.values(item)[0] }}
                                                </p>

                                                <!-- <button v-if="state.sort"
                                                        class="table-arrows"
                                                        @click="sorting"
                                                >
                                                    <svg :id="Object.keys(item)[0]">
                                                        <use xlink:href="../../../assets/img/sprite.svg#arrows-filter"></use>
                                                    </svg>
                                                </button> -->

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="tbody">

                                    <div
                                        v-for="(item, index) of data"
                                        :key="index"
                                        :class="'tr ' + item.class"
                                    >     
    
                                        <div v-if="/short_[a-z]{0,10}_contract/.test(tableStyle)" class="tr-wrap">


                                        <!-- <div v-if="tableStyle.includes('short_agent_contract')" class="tr-wrap"> -->
                                            <div class="td">
                                                <p>{{ item.dateOper }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.agent }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.thing }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.title }}</p>
                                            </div>
                                        </div>

                                        
                                        <div v-if="tableStyle.includes('full_agent_contract')" class="tr-wrap">
                                            <div class="td">
                                                <p>{{ item.dateOper }}</p>
                                            </div>

                                            <div class="td shadow">
                                                <tippy :to="item.id + item.fio" followCursor="true" placement='top-end' theme='light'>
                                                    <div class="tippy table-tippy">{{ item.fio }}</div>
                                                </tippy>
                                                <p :title="item.fio" :name="item.id + item.fio">{{ item.fio }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.agent }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.thing }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.dateStatus }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.title }}</p>
                                            </div>

                                        </div>


                                        <div v-if="tableStyle.includes('full_client_contract')" class="tr-wrap">
                                            <div class="td">
                                                <p>{{ item.dateOper }}</p>
                                            </div>

                                            <div class="td shadow">
                                                <tippy :to="item.id + item.fio" followCursor="true" placement='top-end' theme='light'>
                                                    <div class="tippy table-tippy">{{ item.fio }}</div>
                                                </tippy>
                                                <p :title="item.fio" :name="item.id + item.fio">{{ item.fio }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.agent }}</p>
                                            </div>

                                            <div class="td-text">
                                                <p class="td-text__title">{{ item.product && item.product.title }}</p>
                                                <p class="td-text__desc">{{ item.product && item.product.desc }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.thing }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.title }}</p>
                                            </div>
                                        </div>


                                        <div v-if="tableStyle.includes('full_drafts_contract')" class="tr-wrap" style="grid-template-columns: minmax(125rem, 140rem) minmax(294rem, 2fr) minmax(236rem, 1fr) minmax(218rem, 1fr) minmax(144rem, 200rem)">

                                            <div class="td">
                                                <p>{{ item.dateOper }}</p>
                                            </div>

                                            <div class="td shadow">
                                                <tippy :to="item.id + item.fio" followCursor="true" placement='top-end' theme='light'>
                                                    <div class="tippy table-tippy">{{ item.fio }}</div>
                                                </tippy>
                                                <p :title="item.fio" :name="item.id + item.fio">{{ item.fio }}</p>
                                            </div>

                                            <div class="td">
                                                <p>{{ item.thing }}</p>
                                            </div>

                                            <div class="td">

                                                <button class="btn-middle btn-text primary" 
                                                        @click="() => {

                                                            $cookies.set('id_current_contract', item.id)
                                                            $router.push({ name: 'contractId-1' })
                                                        }">

                                                    <span>Продолжить оформление</span>
                                                </button>
                                            </div>

                                            <div class="td" style="font-weight: 600;">

                                                <svg width="24" height="24">
                                                    <use fill="white" width="24" xlink:href="../../../assets/img/sprite.svg#trash-bukkit"></use>
                                                </svg>

                                                <span style="display: inline-block;"
                                                      @click="propFuncs.deleteDraft.call(null, item.id)">
                                                    Удалить черновик
                                                </span>
                                            </div>

                                        </div>

                                        <div v-if="tableStyle.includes('search_client')" class="tr-wrap" @click="propFuncs.redirectToClient(item)">
                                                                
                                            <div class="td shadow">
                                                <tippy :to="index + item.fio" followCursor="true" placement='top-end' theme='light'>
                                                <div class="tippy table-tippy">{{ item.fio }}</div>
                                                </tippy>
                                                <p :title="item.fio" :name="index + item.fio">{{ item.fio }}</p>
                                                </div>

                                                <div class="td">
                                                    <p>{{ item.mobile | VMask('+7 (###) ###-###-##') }}</p>
                                                </div>

                                                <div class="td">
                                                    <p>{{ item.numContract }}</p>
                                                </div>

                                                <div class="td">
                                                    <p>{{ item.passport | VMask('#### ######')}}</p>
                                                </div>

                                                <div class="td">
                                                    <p>{{ item.snils }}</p>
                                                </div>

                                                <div class="td">
                                                    <p>{{ item.inn }}</p>
                                                </div>
                                            </div> 

                                        </div>

                                </div>

                                <div v-if="!data.length && tableStyle.includes('full')" class="empty">

                                    Здесь пусто

                                </div>


                            </div>
                        </div>

                    
                    </div>

                    <div v-if="existLoad" class="table-more-main">

                        <button ref="moreLoad" 
                                class="btn-middle btn-text secondary"
                                id="more"
                                @click="sorting('btnClick')"
                            >Загрузить еще
                        </button>

                    </div>

                    <div v-if="!state.sort" class="area-buttons btns-end">
                        <div class="area-buttons-block">
                            <router-link
                                :to="`/${listFullPath}`"
                                tag="button"
                                class="btn-middle btn-text secondary"
                                >
                            Весь список
                            </router-link>
                        </div>
                    </div>
                    
                </div>
            
                <div 
                    v-if="!payload"
                    class="small-table-preload"
                >
                    <div class="small-table-preloader">
                        <div class="loader">
                            <img src="@/assets/img/svg/preloader.svg" alt="">
                        </div>
                    </div>
                    <!-- Крутим прелоадер -->
                </div>
            
                <div 
                    v-if="data && data.length === 0 && typeForm === 'short' && payload"
                    class="small-table-push"
                >
                    <p>{{ notDocumentMessage }}</p>               
                </div>

                <div 
                    v-if="data && data.length === 0 && typeForm !== 'short' && payload"
                    class="small-table-empty"
                >
                    Здесь пусто
                </div>
            </transition>
        </div>
    </div>
</template>


<script>

import { rusCryteries } from './cryteries.js'
import Filters from './v-filters'

export default {

    name: 'v-tables',

    props: {
        mainData: Array,
        theme: String,
        group: String,
        typeForm: String,
        name: String,
        tableStyle: String,
        listFullPath: String,
        downloadMore: Function,
        inputsView: Array,
        payload: Boolean,
        
        rowsPaginator: Number,
        propFuncs: Object,
        TotalAmount: Number
    },

    data(){
        return {

            stages: '',

            transition: {
                filter: false,
                table: false
            },

            allowLoader: {
                auto: true,
                scroll: true,
            },

            changeSort: false,

            tableScheme: [],
            styles: {},
            state: {
                cell: 'dateOper',
                mode: 'up',
                historyClass: 'preview',
                sort: false
            },
            data: [],

            renderKey: 0,

        }
    },

    computed: {

        searchClientClass(){
            return {
                [`wrapper-table type-1 history-${this.state.historyClass}`]: this.group !== 'search' ? true : false,
                [`wrapper-table client-list-detail`]: this.group === 'search' ? true : false
            }
        },


        notDocumentMessage(){

            if(this.group === 'contracts'){

                return 'Здесь вы сможете увидеть ранее оформленные вами договоры и статус по их оплате'

            }else{
                return 'Здесь вы сможете увидеть список заявлений, поданных клиентом, а также статус их рассмотрения'
            }

        },

        existLoad(){

            const path = this.$route.path

            const allowBtn = (/history|drafts/.test(path)) 
                                && 
                                this.data.length < this.TotalAmount


            this.allowLoader.auto   = allowBtn
            this.allowLoader.scroll = true

            return allowBtn
        },

    },

    watch: {

        "mainData": function(){
            this.data = this.mainData
            this.setStyles()
            
        }

    },

    mounted(){
        
        setTimeout(() => {
            this.transition.filter = true
        }, 100)

        setTimeout(() => {
            this.transition.table = true
        }, 500)

        this.autoPreloader()

    },

    methods: {

        setStyles(){

            const c = rusCryteries

            switch(this.tableStyle){

                case 'search_client':

                    this.tableScheme = [
                        { fio:         c.fio },
                        { mobile:      c.mobile },
                        { numContract: c.numContract },
                        { passport:    c.passport },
                        { snils:       c.snils },
                        { inn:         c.inn }
                    ]
                    this.state.historyClass = 'detail'
                    this.state.sort = true

                    this.styleClients()
                    break

                case 'short_agent_contract':

                    this.tableScheme = [
                        { dateOper: c.dateOper },
                        { agent:    c.agent },
                        { thing:    c.thing },
                        { status:   c.status },
                    ]

                    this.styleContract()
                    break


                case 'full_agent_contract':

                    this.tableScheme = [
                        { dateOper:   c.dateOper },
                        { fio:        c.fio },
                        { agent:      c.agent },
                        { thing:      c.thing },
                        { dateStatus: c.dateStatus },
                        { status:     c.status }
                    ]
                    this.state.historyClass = 'detail'
                    this.state.sort = true

                    this.styleContract()
                    break


                case 'full_client_contract':

                    this.tableScheme = [
                        { dateOper:   c.dateOper }, 
                        { fio:        c.fio }, 
                        { agent:      c.agent }, 
                        { product:    c.product },
                        { thing:      c.thing }, 
                        { status:     c.status }
                    ]
                    this.state.historyClass = 'detail'
                    this.state.sort = true

                    this.styleContract()
                    break


                case 'full_drafts_contract':

                    this.tableScheme = [
                        { dateOper:    c.dateOper },
                        { fio:         c.fio },
                        { thing:       c.thing },
                    ]
                    this.state.historyClass = 'detail'
                    this.state.sort = true

                    this.styleContract()
                    break
            }

            this.renderKey = Date.now()

        },


        styleContract() {
            
            const stylingThing = (note) => {
                switch(note){

                    case '5':
                        return {
                            title: 'Оплачен',
                            class: 'accepted'
                        }

                    case '4':
                        return {
                            title: 'Ожидает зачисления',
                            class: 'default'
                        }

                    case '3':
                        return {
                            title: 'Ожидает оплаты',
                            class: 'default'
                        }

                    case '2':
                        return {
                            title: 'На проверке',
                            class: 'default'
                        }

                    case '-1':
                        return {
                            title: 'Отклонено',
                            class: 'rejected'
                        }

                    default:
                        return {
                            title: 'На оформлении',
                            class: 'default'
                        }
                }
            }



            let status = ''
            

            for(let item of this.data){
                
                status = item.status.toString()

                //проверка на уже присвоенный статус
                if(status.includes('о')){
                    continue;
                }

                item['thing'] = 'Оформление договора'
                item['class'] = stylingThing(status).class
                item['title'] = stylingThing(status).title
                
            }


        },


        styleClients(){

            function getViewNumber(data){

                let result = { ops: [], npo: [] },
                    numDoc = 'Нет действующего договора',
                    dateUnix = ''

                if(!data.length) {
                    return numDoc
                }

                data.forEach(item => {

                    dateUnix = item.agr_date_signed.split('-')
                    dateUnix = Date.UTC(...dateUnix)

                    if(item.type === 'NPO'){

                        result.npo.push({ ...item, agr_date_signed: dateUnix })

                    }else{
                        result.ops.push({ ...item, agr_date_signed: dateUnix })
                    }

                })

                const getHeightNumber = function(data){

                    data = data.reduce((acc, curr) => {

                        if(curr.agr_date_signed > acc.agr_date_signed){

                                return curr

                            }else{
                                return acc
                            }

                    }, data[0])
                    
                    return data
                }

                if(result.ops.length){
                    
                    result = getHeightNumber(result.ops)

                    return 'OPS ' + result.number
                    
                }else{

                    result = getHeightNumber(result.npo)

                    return 'NPO ' + result.number
                }
            
            }


            for(let item of this.data){

                item['class'] = 'default'
                item['numContract'] = getViewNumber(item.contracts)

            }
        },

        async sorting(e){

            let countLoad = 0,
                       id = 0

            if(e !== 'autoload' && e !== 'btnClick'){

                id = (e.target.tagName !== 'svg')
                            ? e.target.parentNode.id
                            : e.target.id
                            

                if(e.target.id !== 'more'){

                    
                    countLoad = this.rowsPaginator

                    if(id === this.state.cell){

                        this.state.mode = (this.state.mode === 'up') ? 'down' : 'up'

                    }else{

                        this.state.mode = 'up'
                        this.state.cell = id

                    }

                    // this.changeSort = true

                }else{
                    // this.changeSort = false
                }

            }else{
                // this.changeSort = false
            }

                        let stages = ''


            switch(this.group){


                case 'contracts':
                    stages = { stage: this.stages }
                    break

                case 'statements':
                    stages = { statuses: this.stages }
                    break

                case 'drafts':
                    stages = { stage: '0, 1' }


            }


            await this.downloadMore({ limit: { count_from: this.mainData.length - countLoad }, sort: { 
                                                        order_by: this.state.cell, 
                                                        order_by_direction: this.state.mode 
                                                    },
                                                btnClick: e === 'btnClick' ? true : false,
                                                startType: 'add',
                                                stages,
                                                }
                                            );

            setTimeout(() => {
                this.data = this.mainData
            },0)

        },
        
        // фун-ия скорее всего будет вырезана. Не соответствует логике кнопки
        async resetSort(){

            this.state.cell = 'dateOper'
            this.state.mode = 'up'

            let stages = ''

            switch(this.group){

                case 'contracts':
                    stages = { stage: '-1, 2, 3, 4, 5' }
                    break

                case 'drafts':
                    stages = { stage: '0, 1' }

            }



            await this.downloadMore({ limit: { count_from: 0 }, sort: { 
                                            order_by: this.state.cell, 
                                            order_by_direction: this.state.mode 
                                        },
                                    stages,
                                    startType: 'filter'}
                                );

            this.changeSort = true
        },


        autoPreloader(){

            window.onscroll = (e) => {

                const btnMore  = this.$refs.moreLoad

                if(!btnMore) return;

                const userHeight = e.path[1].outerHeight - 50,
                      beforeBtn  = btnMore.getBoundingClientRect().y,
                { scroll, auto } = this.allowLoader


                if(scroll && auto && btnMore && userHeight > beforeBtn){

                    this.allowLoader.scroll = false
                    this.sorting('autoload')

                }


            }


        }

    },

    components: {
        Filters
    }

}
</script>
